import es from 'date-fns/locale/es';
import React, { Component } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ServiceDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fecha: this.props.default ? this.props.default : null //new Date()
    }

    this.handleChange = this.handleChange.bind(this);
  }

  ///////////////////
  componentDidMount() {
    // local
    registerLocale('es', es);
    setDefaultLocale('es');

    this.setState({ isLoading: false });
  }

  handleChange(value) {
    this.setState({
      fecha: value
    });
    this.props.onChange(this.props.name, value);
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    else {
      return (
        <div className="d-flex flex-column">
          {this.props.title && <div className="w-100">{this.props.title}</div>}
          <DatePicker
            name={this.props.name}
            className="form-control form-control-sm border"
            selected={this.state.fecha}
            onChange={this.handleChange}
            dateFormat="dd/MM/yyyy"
            readOnly={this.props.typing ? this.props.typing : false }
            minDate={this.props.disabled ? (this.props.default ? this.props.default : this.state.fecha) : (this.props.mindate ? this.props.mindate : null)}
            maxDate={this.props.disabled ? (this.props.default ? this.props.default : this.state.fecha) : (this.props.maxdate ? this.props.maxdate : null)}
            disabledKeyboardNavigation
            inline={this.props.inline ? this.props.inline : null}
          />
        </div>
      );
    }
  }
}

export default ServiceDate;



