import React, { Component } from 'react';

class ServiceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: this.props.default ? this.props.default : ''
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(evt) {
    evt.persist();
/*    console.log("ServiceInput::handleChange::evt::", evt);*/
    const value = evt.target.value;
    this.setState({
      value: value
    });
    this.props.onChange(this.props.name, value);
  }
  
  ///////////////////
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    else {
      return (
        <div className="d-flex flex-column">
          {this.props.title && <div>{this.props.title}</div>}
          {this.props.type == 'textarea'
            ?
            <textarea
              name={this.props.name}
              defaultValue={this.state.value}
              onChange={this.handleChange}
              className={this.props.className ? this.props.className : "form-control"}
              style={{ fontSize: 'small' }}
              rows={this.props.rows ? this.props.rows : null}
              disabled={this.props.disabled ? this.props.disabled : false}
              placeholder={this.props.placeholder} />
            :
             <input
              name={this.props.name}
              type={this.props.type ? this.props.type : 'text'}
              defaultValue={this.state.value}
              onChange={this.handleChange}
              className={this.props.className ? this.props.className : "form-control form-control-sm"}
              autoFocus={this.props.autofocus ? this.props.autofocus : false}
              disabled={this.props.disabled ? this.props.disabled : false}
              placeholder={this.props.placeholder} />
          }

        </div>
      );
    }
  }
}

export default ServiceInput;



