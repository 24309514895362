
import React, { Component } from 'react';
import { AppContext } from 'App'

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ServiceSelect from 'components/Util/ServiceSelect';
import ServiceDate from 'components/Util/ServiceDate';
import ServiceInput from 'components/Util/ServiceInput';
import ServiceBool from 'components/Util/ServiceBool';


class BuscarPartes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoParte: null,
            numeroParte: 0,
            cliente: '',
            jefeProyecto: '',
            estado: [{ value: '1', label: 'Abierto' }, { value: '4', label: 'Asignado' }], // Por defecto solo abiertos y asignados
            fechaDesde: null, //new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: null, //new Date(),
            verTodo: false,

            isLoaded: true,
            showModal: false
        }

        // Context
        BuscarPartes.contextType = AppContext;

        //console.log("this.props.item::", this.context);

        // Modal
        this.hChange = this.hChange.bind(this);
        this.hSelPartes = this.hSelPartes.bind(this);

    }

    hChange(name, value) {
        //console.log("Partes::name::", name);
        //console.log("Partes::value::", value);
        this.listapartes = '';
        this.setState({
            [name]: value
        }, () => {
            this.hSelPartes();
        });
    }

    // Seleccionar partes
    hSelPartes() {
        //console.log("hSelPartes::this.state::", this.state);
        this.item = {
            //tipoParte: this.state.tipoParte ? JSON.stringify(this.state.tipoParte) : '',
            tipoParte: this.state.tipoParte ? this.state.tipoParte : '',
            numeroParte: this.state.numeroParte ? this.state.numeroParte : 0,
            cliente: this.state.cliente ? this.state.cliente : '',
            jefeProyecto: this.state.jefeProyecto ? this.state.jefeProyecto : '',
            estado: this.state.estado ? JSON.stringify(this.state.estado) : '',
            fechaDesde: this.state.fechaDesde,
            fechaHasta: this.state.fechaHasta,
            verTodo: this.state.verTodo
        }
        this.props.onSelect(this.item);
    }


    render() {
        return (
            <>
                <div className="border p-1">
                    <div className="d-flex flex-row justify-content-start">
                        <div className="w-10 px-1">
                            <ServiceSelect onChange={this.hChange} name="tipoParte" service="ListaTiposParteSelect" title="Tipo de parte" first={{ value: '', label: '--Todos--' }} default={{ value: '', label: '--Todos--' }} />
                        </div>
                        {this.context.Empleado.CodigoDepartamento != 'EXTERN' ? (
                        <div className="w-20 px-1">
                            <ServiceSelect onChange={this.hChange} name="jefeProyecto" service="ListaJefesProyectoSelect" title="Jefe de proyecto" first={{ value: '', label: '--Todos--' }} default={{ value: '', label: '--Todos--' }} />
                            </div>
                        ) : null
                        }
                        {this.context.Empleado.CodigoDepartamento != 'EXTERN' ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChange} name="cliente" service="ListaClientesSelect" title="Cliente" first={{ value: '', label: '--Todos--' }} default={{ value: '', label: '--Todos--' }} />
                            </div>) : null
                        }
                        <div className="w-10 px-1">
                            <ServiceInput onChange={this.hChange} name="numeroParte" title="N&uacute;mero de parte" />
                        </div>
                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hChange} name="fechaDesde" title="Fecha desde" />
                        </div>
                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hChange} name="fechaHasta" title="Fecha hasta" />
                        </div>
                        <div className="w-20 px-1">
                            <ServiceSelect onChange={this.hChange} name="estado" service="ListaEstadosParteSelect" title="Estado" multiple={true} default={this.state.estado} />
                        </div>
                        {this.context.EsJefeProyecto ?
                            <div className="px-1">
                                <ServiceBool onChange={this.hChange} name="verTodo" title="Ver todos" size="sm" default={false} />
                            </div>
                            : null}
                        <div className="px-1">
                            <div className="d-flex flex-column">
                                <div>&nbsp;</div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.hSelPartes}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BuscarPartes;


