
import React, { Component } from 'react';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ServiceSelect from 'components/Util/ServiceSelect';


class BuscarClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cliente: '',
      isLoading: false
    }

    //console.log("this.props.item::", this.props.item);

    // Modal
    this.hChange = this.hChange.bind(this);
    this.hSelClientes = this.hSelClientes.bind(this);

  }

  componentDidMount() {
    //console.log("BuscarClientes:DidMount::props::", this.props);
  }

  hChange(name, value) {
    //console.log("Partes::name::", name);
    //console.log("Partes::value::", value);
    this.setState({
      [name]: value
    }, () => {
        this.hSelClientes();
    });
  }

  // Seleccionar partes
  hSelClientes() {
    //console.log("hSelClientes::this.state::", this.state);
    this.item = {
      CodigoCliente: this.state.cliente ? this.state.cliente : ''
    }
    this.props.onSelect(this.item);
  }


  render() {
    //if (this.state.isLoading) {
    //  return <div>Loading....</div>
    //}
    //else {
      return (
        <>
          <div className="d-flex flex-row justify-content-start">
            <div className="w-40 px-1">
              <ServiceSelect onChange={this.hChange}
                name="cliente"
                service="ListaClientesSelect"
                title="Buscar cliente"
                placeholder="" />
            </div>
            <div className="w-10 px-1">
              <div className="d-flex flex-column">
                <div>&nbsp;</div>
                <div>
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.hSelClientes}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
              </div>
              </div>
            </div>
          </div>

        </>
      );
    //}
  }
}

export default BuscarClientes;

