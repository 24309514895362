import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    flex: 1,
    marginTop: 10,
  },
  footer: {
    alignContent: 'left',
    alignSelf: 'left',
  },
  detailContainer: {
    fontSize: 7,
    flexDirection: 'row',
  },
  detailDoc: {
    fontWeight: 'extrabold',
    paddingTop: '5px',
    paddingBottom: '5px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    marginTop: 3,
  },
  line: {
    width: '100%',
    borderTop: 2,
    borderTopColor: "#0000FF",
  },
  detailPlace: {
    paddingTop: '5px',
    paddingBottom: '5px',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  itemContent: {
    flex: 1,
    fontSize: 8,
  },
  text: {
    justifyContent: 'flex-start'
  }
});

export default (props) => (
  <>
    <View style={styles.container}>

      <View style={styles.footer}>
        <Text style={styles.detailContainer}>
          Es responsabilidad del cliente proceder a la validación de los servicios prestados y los entregables que se le proporcionen. La firma de este documento por parte del CLIENTE es equivalente
          a la recepción de los Servicios a conformidad. Si dentro de los cinco (5) días naturales posteriores a la finalización o entrega de los Servicios ESMA DATA no ha recibido ninguna notificación en
          contrario, los Servicios se considerarán definitivamente recibidos.
        </Text>
        <Text style={styles.detailDoc}>
          DOC12-V01
        </Text>
        <Text style={styles.line}>
        </Text>
        <Text style={styles.detailPlace}>
          BARCELONA - BILBAO - CANTABRIA - LLEIDA - MADRID - MÁLAGA - SEVILLA - VALENCIA - VITORIA - ZARAGOZA
        </Text>
      </View>

    </View>
  </>
);

