import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class ServiceFileDnd extends Component {
  constructor(props) {
    super();
    this.onDrop = (files) => {
      files.map(file => {
        this.setState({
          file: URL.createObjectURL(file)
        }, () => {
          //console.log("file::", this.state.file);
          props.onChange(this.props.name, this.state.file);
        })
      });
    };
    this.state = {
      files: []
    };
  }

  render() {
    return (
      <>
        {this.props.title && <div>{this.props.title}</div>}
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
            let classes = 'card card-body cursorpointer';
            if (isDragAccept) {
              classes = `${classes} border-success bg-light`;
            }
            if (isDragReject) {
              classes = `${classes} border-danger bg-light`;
            }
            return (
              <section>
                <div {...getRootProps()} className={classes}>
                  <input {...getInputProps()} />
                  <img src={this.state.file} />
                  <p>{this.props.message}</p>
                </div>
              </section>
            )
          }}
        </Dropzone>
      </>
    );
  }
}

export default ServiceFileDnd;