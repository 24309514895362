import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import { AppContext } from 'App'

import Dropdown from 'react-bootstrap/Dropdown';

import BuscarPartes from './BuscarPartes';
import ModalCliente from 'components/Cliente/ModalCliente';
import ModalDetalleParte from './ModalDetalleParte';
import ModalFinalizarParte from './ModalFinalizarParte';
import ModalImprimirParteAsistencia from './ModalImprimirParteAsistencia';

// pagination
import Pagination from "react-js-pagination";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCog, faEdit, faFilePdf, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';

class ListaPartes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tipoParte: '',
            numeroParte: 0,
            cliente: '',
            jefeProyecto: '',
            estado: [{ value: '1', label: 'Abierto' }, { value: '4', label: 'Asignado' }], // Por defecto solo abiertos y asignados
            fechaDesde: null, // new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: null, // new Date(),
            verTodo: false,
            showBuscarPartes: false,
            showDetalleParte: false,
            showFinalizarParte: false,
            showImprimirParteAsistencia: false,
            showImputar: false,
            itemSelected: {},
            listapartes: [],
            listaview: [],

            // pagination
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            //pages: [],
            //pageActive: 1,
            //itemsPage: 25  // n�mero de items por p�gina
        }
        ListaPartes.contextType = AppContext;

        ////
        this.hSelPartes = this.hSelPartes.bind(this);
        this.hSeleccionarParte = this.hSeleccionarParte.bind(this);
        // Modal Cliente
        this.hCloseCliente = this.hCloseCliente.bind(this);
        this.hShowCliente = this.hShowCliente.bind(this);
        // Modal Detalle Parte
        this.hShowDetalleParte = this.hShowDetalleParte.bind(this);
        this.hCloseDetalleParte = this.hCloseDetalleParte.bind(this);
        this.hChangeDetalleParte = this.hChangeDetalleParte.bind(this);
        // Modal Finalizar Parte
        this.hShowFinalizarParte = this.hShowFinalizarParte.bind(this);
        this.hCloseFinalizarParte = this.hCloseFinalizarParte.bind(this);
        // Modal Finalizar Parte
        this.hShowImprimirParteAsistencia = this.hShowImprimirParteAsistencia.bind(this);
        this.hCloseImprimirParteAsistencia = this.hCloseImprimirParteAsistencia.bind(this);
        // Modal Imputar
        this.hCloseImputar = this.hCloseImputar.bind(this);
        this.hShowImputar = this.hShowImputar.bind(this);

        this.formatDate = this.formatDate.bind(this);
    }

    ///////////////////
    componentDidMount() {
        this.hSelPartes();
    }

    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    // Select partes
    hSelPartes(item) {
        //console.log("hSelPartes::item::", item);

        this.setState({ isLoading: true });

        let data = {};
        if (item) {
            data = {
                JsonTipoParte: item.tipoParte ? JSON.stringify(item.tipoParte) : '',
                TipoParte: item.tipoParte ? item.tipoParte : '',
                NumeroParteLc: item.numeroParte,
                CodigoCliente: item.cliente ? item.cliente : '',
                JefeProyecto: item.jefeProyecto ? item.jefeProyecto : '',
                JsonStatusParteLc: item.estado ? item.estado : '',
                FechaDesde: this.formatDate(item.fechaDesde),
                FechaHasta: this.formatDate(item.fechaHasta),
                VerTodo: item.verTodo
            }
        }
        else {
            data = {
                JsonTipoParte: this.state.tipoParte ? JSON.stringify(this.state.tipoParte) : '',
                TipoParte: this.state.tipoParte ? this.state.tipoParte : '',
                NumeroParteLc: this.state.numeroParte,
                CodigoCliente: this.state.cliente ? this.state.cliente.value : '',
                JefeProyecto: this.state.jefeProyecto ? this.state.jefeProyecto.value : '',
                JsonStatusParteLc: this.state.estado ? JSON.stringify(this.state.estado) : '',
                FechaDesde: this.formatDate(this.state.fechaDesde),
                FechaHasta: this.formatDate(this.state.fechaHasta),
                VerTodo: this.state.verTodo
            }
        }
        //console.log("data::", data);
        api.post("/ListaPartes", data).then(res => {
            //console.log("this.listapartes::", this.listapartes);
            //
            this.setState({ listapartes: res.data, isLoading: false, showBuscarPartes: false, showDetalleParte: false, parteSeleccionado: null });
            this.props.onSelect(null);

            // Paginaci�n
            this.setState({
                totalItemsCount: res.data.length,
            })
            this.setPagination(1);

        });
    }

    setPagination(item) {
        //console.log("setPagination::n::", item);
        var list = [];
        var ini = (item - 1) * this.state.itemsCountPerPage;
        var fin = (item) * this.state.itemsCountPerPage;
        for (var i = ini; i < this.state.listapartes.length && i < fin; i++) {
            list.push(this.state.listapartes[i]);
        }
        this.setState({ activePage: item, listaview: list });
    }


    // Modal Cliente
    hCloseCliente() {
        this.setState({
            showCliente: false
        });
    }
    hShowCliente = item => () => {
        this.setState({
            showCliente: true,
            itemSelected: item
        });
    }

    // Modal Detalle Parte
    hShowDetalleParte(item) {
        //console.log("ListaPartes::hShowDetalleParte::", item);
        this.setState({
            showDetalleParte: true,
            itemSelected: item
        });
    }
    hCloseDetalleParte() {
        this.setState({
            showDetalleParte: false
        });
    }
    hChangeDetalleParte() {
        this.setState({
            showDetalleParte: false
        });
        this.hSelPartes();
    }

    // *************************
    // Modal Finalizar Parte
    hShowFinalizarParte(item) {
        this.setState({
            showFinalizarParte: true,
            itemSelected: item
        });
    }
    hCloseFinalizarParte() {
        this.setState({
            showFinalizarParte: false
        });
        this.hSelPartes();
    }
    // *************************

    // *************************
    // Modal Imprimir parte asistencia
    hShowImprimirParteAsistencia(item) {
        this.setState({
            showImprimirParteAsistencia: true,
            itemSelected: item
        });
    }
    hCloseImprimirParteAsistencia() {
        this.setState({
            showImprimirParteAsistencia: false
        });
        this.hSelPartes();
    }
    // *************************


    // Imputar parte �?�?�?
    hCloseImputar() {
        this.setState({
            showImputar: false
        });
    }
    hShowImputar(item) {
        //console.log("ListaPartes::hShowImputar::", item);
        //console.log("ListaPartes::hShowImputar::this.state.parteSeleccionado::", this.state.parteSeleccionado);
        this.props.onSelect(item);
    }

    hSeleccionarParte(item) {
        //console.log("hSeleccionarParte");
        this.setState({
            //parteSeleccionado: this.state.parteSeleccionado === item.NumeroParteLc ? null : item.NumeroParteLc
            parteSeleccionado: item.NumeroParteLc
        });
    }

    render() {
        return (
            <>
                {/* Lista de partes */}
                <div>
                    <div className="border p-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Lista de partes</div>
                        </div>
                    </div>

                    {/* Buscar Partes*/}
                    <BuscarPartes onSelect={this.hSelPartes} />

                    <div className="mt-1">
                        <table className='table table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Parte</th>
                                    <th>Serie</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Responsable Parte</th>
                                    <th>T&eacute;cnico asignado</th>
                                    <th>Proyecto</th>
                                    <th style={{ width: '400px' }}>Observaciones</th>
                                    <th style={{ width: '70px' }}>Estado</th>
                                    <th style={{ width: '70px', textAlign: 'right' }}>Total:: {this.state.listapartes.length}</th>
                                    <th style={{ width: '10px' }}>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isLoading ?
                                    <tr><td colSpan="11">Loading...</td></tr>
                                    : null
                                }
                                {!this.state.isLoading && !this.state.listapartes.length ?
                                    <tr><td colSpan="11">No hay datos para la consulta</td></tr>
                                    : null
                                }
                                {!this.state.isLoading && this.state.listapartes.length ?
                                    this.state.listaview.map(item =>
                                        <tr className={"cursorpointer " + (this.state.parteSeleccionado === item.NumeroParteLc ? 'bg-grey' : '')}
                                            onDoubleClick={() => this.hShowDetalleParte(item)}
                                            key={item.IdParte}>
                                            <td>{item.NumeroParteLc}</td>
                                            <td>{item.SerieParteLc}</td>
                                            <td>{item.CodigoTipoParteLc}</td>
                                            <td>{item.Fecha}</td>
                                            <td><a className="link underline" onClick={this.hShowCliente(item)}>{item.NombreCliente}</a></td>
                                            <td>{item.NombreResponsableParteLc}</td>
                                            <td>{item.NombreTecnicoAsignadoLc}</td>
                                            <td>{item.CodigoProyecto}</td>
                                            <td>{item.Observaciones}</td>
                                            <td>{item.StatusParteLcStr}</td>
                                            <td style={{ textAlign: 'right' }}>
                                                <Dropdown onToggle={() => this.hSeleccionarParte(item)}>
                                                    <Dropdown.Toggle size="sm" variant="light">
                                                        <FontAwesomeIcon icon={faCog} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item disabled={!item.Activo || !item.EsImputable} onClick={() => this.hShowImputar(item)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faCalendarAlt} /></span>&nbsp;Imputar</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.hShowDetalleParte(item)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faEdit} /></span>&nbsp;Detalle parte</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.hShowImprimirParteAsistencia(item)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faFilePdf} /></span>&nbsp;Parte Asistencia</Dropdown.Item>
                                                        <Dropdown.Item disabled={!item.Activo || !item.EsImputable} onClick={() => this.hShowFinalizarParte(item)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faHourglassEnd} /></span>&nbsp;Finalizar Parte</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    )
                                    : null
                                }
                            </tbody>
                        </table>
                        <div className="d-flex flex-row justify-content-center">
                            <div>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    onChange={this.setPagination.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    prevPageText="<"
                                    nextPageText=">"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal de clientes */}
                {(this.state.itemSelected && this.state.showCliente) ?
                    <ModalCliente
                        show={this.state.showCliente}
                        item={this.state.itemSelected}
                        onClose={this.hCloseCliente}
                    /> : null}

                {/* Modal detalle parte */}
                {(this.state.showDetalleParte) ?
                    <ModalDetalleParte
                        show={this.state.showDetalleParte}
                        parte={this.state.itemSelected}
                        onClose={this.hCloseDetalleParte}
                        onChange={this.hChangeDetalleParte}
                    /> : null}

                {/* Modal finalizar parte */}
                {(this.state.showFinalizarParte) ?
                    <ModalFinalizarParte
                        show={this.state.showFinalizarParte}
                        parte={this.state.itemSelected}
                        onClose={this.hCloseFinalizarParte}
                    /> : null}

                {/* Modal imprimir parte asistencia */}
                {(this.state.showImprimirParteAsistencia) ?

                    <ModalImprimirParteAsistencia
                        show={this.state.showImprimirParteAsistencia}
                        parte={this.state.itemSelected}
                        onClose={this.hCloseImprimirParteAsistencia}
                    /> : null}

            </>
        );
    }
}

export default ListaPartes;




