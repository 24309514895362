
import React, { Component, useState, useEffect } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'

import Modal from 'react-bootstrap/Modal';
import CampoFicha from 'components/Util/CampoFicha';
import ServiceInput from 'components/Util/ServiceInput';
import ServiceSelect from 'components/Util/ServiceSelect';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-regular-svg-icons';



class ModalDetalleParte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.parte,
            isLoading: true
        }
        ModalDetalleParte.contextType = AppContext;

        this.handleChange = this.handleChange.bind(this);
        // Modal
        this.handleClose = this.handleClose.bind(this);
        this.handleAcept = this.handleAcept.bind(this);
        this.handleShowTecnico = this.handleShowTecnico.bind(this);
        this.handleHideTecnico = this.handleHideTecnico.bind(this);
    }

    ///
     componentDidMount() {
        //console.log("ModalDetalleParte::componentDidMount:");
        this.setState({
            isLoading: true,
            parte: {}
        });

        // Lista Técnicos
         api.post("/ListaTecnicosSelect", {}).then(res => {
            //console.log("ListaTecnicosSelect::res.data::", res.data);
            this.setState({
                tecnicos: res.data
            });
        });

         let data = {
            CodigoEmpresa: this.props.parte.CodigoEmpresa,
            NumeroParteLc: this.props.parte.NumeroParteLc,
            SerieParteLc: this.props.parte.SerieParteLc,
            EjercicioParteLc: this.props.parte.EjercicioParteLc,
            CodigoTipoParteLc: this.props.parte.CodigoTipoParteLc
        }
        // Parte
         api.post("/LeerParte", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({
                isLoading: false,
                parte: res.data
            });
        });
    }

    // 
    handleChange(name, value) {
        //console.log("ModalImputacion::name::", name);
        //console.log("ModalImputacion::value::", value);
        this.setState({
            [name]: value
        });
    }

    // Modal
    handleClose() {
        this.props.onClose();
        this.setState({
            showModal: false
        });
    }
    handleAcept() {
        //console.log("ModalFinalizarParte::handleAcept::this.state::", this.state);
        this.setState({ isLoading: true });
        let data = this.state;
        api.post("/ModificarParte", data).then(res => {
            //console.log("res.data::", res.data);

            this.props.onChange();
            this.setState({
                showModal: false
            });
        });
    }
    handleShowTecnico() {
        this.setState({ showTecnicoParte: true });
    }
    handleHideTecnico() {
        this.setState({ showTecnicoParte: false });
    }


    render() {
        if (this.state.isLoading) {
            return (
                <Modal
                    size="xl"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            N.Parte:&nbsp;{this.props.parte.NumeroParteLc}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Loading....</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else {
            return (
                <>
                    <Modal
                        size="xl"
                        show={this.props.show}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                N.Parte:&nbsp;{this.state.parte.NumeroParteLc}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col col-lg-2">
                                    <CampoFicha label="Tipo" value={this.state.parte.CodigoTipoParteLc} />
                                </div>
                                <div className="col col-lg-2">
                                    <CampoFicha label="Fecha" value={this.state.parte.Fecha} />
                                </div>
                                <div className="col col-lg-2">
                                    <CampoFicha label="Proyecto" value={this.state.parte.CodigoProyecto} />
                                </div>
                                <div className="col col-lg-6">
                                    <CampoFicha label="J.Proyecto" value={this.state.parte.NombreResponsableParteLc} />
                                </div>
                                <div className="col-4">
                                    <CampoFicha label="Cliente" value={this.state.parte.NombreCliente} />
                                </div>
                                <div className="col col-lg-4">
                                    <CampoFicha label="Domicilio" value={this.state.parte.Domicilio} />
                                </div>
                                <div className="col col-lg-4">
                                    <CampoFicha label="Contacto" value={this.state.parte.PersonaContactoLc} />
                                </div>
                                <div className="col col-lg-4">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col col-lg-6">*/}
                                    {/*        <CampoFicha label="Horas Vendidas" value={this.state.parte.ESMA_HorasDisponibles} />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col col-lg-6">*/}
                                    {/*        <CampoFicha label="Horas Realizadas" value={this.state.parte.HorasRealizadas} />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                   
                                    <CampoFicha label="Tecnico asignado" value={this.state.NombreTecnicoAsignadoLc} />
                                    {this.state.parte.EsResponsableParte && this.state.parte.Activo ?
                                        <div className="btn-toolbar mt-2">
                                            <div className="link" onClick={this.handleShowTecnico}>Cambiar t&eacute;cnico parte</div>
                                        </div>
                                            : null}
                                 
                                </div>
                                <div className="col-8">
                                    <div className="d-flex flex-column">
                                        <div className="control-label">Observaciones parte</div>
                                        <textarea className="form-control" rows="3" value={this.state.Observaciones ? this.state.Observaciones : ''} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-1">
                                <TablaFasesParte parte={this.state.parte} tecnicos={this.state.tecnicos} />
                                <TablaImputacionesParte parte={this.state.parte} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                        </Modal.Footer>
                    </Modal>


                    {/* showTecnico */}
                    {(this.state.showTecnicoParte) ?
                        <Modal
                            size="md"
                            show={this.state.showTecnicoParte}
                            backdrop="static"
                            onHide={this.handleHideTecnico}
                            keyboard={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    T&eacute;cnico Asignado
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Cambiar t&eacute;cnico asignado?</p>
                                <div className="col-12">
                                    <ServiceSelect onChange={this.handleChange}
                                        name="tecnicoAsignadoLc"
                                        list={this.state.tecnicos}
                                        title="Tecnico asignado"
                                        disabled={this.state.parte.EsResponsableParte && this.state.parte.Activo ? false : true}
                                        default={this.state.TecnicoAsignadoLc ? { value: this.state.TecnicoAsignadoLc, label: this.state.NombreTecnicoAsignadoLc } : null} />
                                </div>
                                <div className="col-12">
                                    <ServiceInput onChange={this.handleChange}
                                        name="Observaciones"
                                        title="Observaciones"
                                        placeholder="Observaciones"
                                        type="textarea"
                                        rows={3}
                                        disabled={this.state.parte.EsResponsableParte && this.state.parte.Activo ? false : true}
                                        default={this.state.Observaciones} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-sm btn-primary" onClick={this.handleHideTecnico}>Cancelar</button>&nbsp;
                                <button type="button" className="btn btn-sm btn-danger" onClick={this.handleAcept}>Aceptar</button>
                            </Modal.Footer>
                        </Modal>

                        : null}

                </>
            );
        }
    }
}

export default ModalDetalleParte;


/////////////////////////////////////////////

function TablaFasesParte(props) {
    const [init, setInit] = useState(false);
    const [tecnico, setTecnico] = useState(0);
    const [tecnicoFase, setTecnicoFase] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [fases, setFases] = useState(null);

    useEffect(() => {
        if (!init) {
            setInit(true);
            listafases();
        }
    });

    //////
    function listafases() {
        setFases(null);
        let data = {
            NumeroParteLc: props.parte.NumeroParteLc,
            SerieParteLc: props.parte.SerieParteLc,
            EjercicioParteLc: props.parte.EjercicioParteLc
        }
        // Parte
        api.post("/ListaFasesParte", data).then(res => {
            //console.log("ListaFasesParte::res.data::", res.data);
            setFases(res.data);
        });
    }

    function cambiar(c) {
        //console.log("cambiar::c::", c);
        setTecnico(c);
        setShowConfirm(true);
    }

    // Confirm
    function setTecnicoAsignado(name, value) {
        //console.log("setTecnicoAsignado::", name, value);
        //console.log("setTecnicoAsignado::", fases);
        setTecnicoFase(value);
        //setShowConfirm(true);
    }
    function hAcept() {
        setShowConfirm(false);

        let data = tecnico;
        data.TecnicoAsignadoLc = tecnicoFase;
        api.post("/ModificarFase", data).then(res => {
            //console.log("res.data::", res.data);
            listafases();
        });
    }
    function hCancel() {
        setShowConfirm(false);
        //listafases();
        //console.log("Cancel");
    }

    return (
        <>
            <div className="border p-1 mt-1 bg-light">
                <div className="d-flex flex-row justify-content-between">
                    <div>Lista de Fases</div>
                </div>
            </div>
            <table className='table table-sm table-hover' aria-labelledby="tabelLabel">
                <thead className="thead-dark">
                    <tr>
                        <th>Fase</th>
                        <th>Responsable Fase</th>
                        <th>T&eacute;cnico asignado</th>
                        <th style={{ textAlign: 'center' }}>Horas Previstas</th>
                        <th style={{ textAlign: 'center' }}>Horas Realizadas</th>
                        {/*<th style={{ textAlign: 'center' }}>Horas Facturables</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {!fases ? null :
                        fases.map(c =>
                            <tr key={c.LineasPosicion}>
                                <td>{c.CodigoTipoFaseLc}-{c.TipoFaseLc}</td>
                                <td>{c.NombreResponsableFaseLc}</td>
                                <td>
                                    {!c.EsResponsableFase || !props.parte.Activo ? c.NombreTecnicoAsignadoLc :
                                        <div className="input-group input-group-sm">
                                            <div type="text" className="form-control form-control-sm">{c.NombreTecnicoAsignadoLc}</div>
                                            <div className="input-group-append">
                                                <button className="btn btn-sm btn-outline-primary" type="button" disabled={!c.EsResponsableFase || !props.parte.Activo} onClick={() => cambiar(c)}><FontAwesomeIcon icon={faEdit} /></button>
                                            </div>
                                        </div>
                                    }
                                </td>
                                <td style={{ textAlign: 'center' }}>{c.HorasPrevistas}</td>
                                <td style={{ textAlign: 'center' }}>{c.HorasRealizadas}</td>
                                {/*<td style={{ textAlign: 'center' }}>{c.TotalHorasFacturables}</td>*/}
                            </tr>
                        )}
                </tbody>
            </table>

            {/* Confirm */}
            {(showConfirm) ?
                <Modal
                    size="md"
                    show={showConfirm}
                    backdrop="static"
                    onHide={hCancel}
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            T&eacute;cnico Asignado
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Cambiar t&eacute;cnico asignado?</p>
                        <div>
                            <ServiceSelect onChange={setTecnicoAsignado}
                                name="tecnicoAsignado"
                                list={props.tecnicos}
                                disabled={!tecnico.EsResponsableFase || !props.parte.Activo ? true : false}
                                default={tecnico.TecnicoAsignadoLc ? { value: tecnico.TecnicoAsignadoLc, label: tecnico.NombreTecnicoAsignadoLc } : null} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-sm btn-primary" onClick={hCancel}>Cancelar</button>&nbsp;
                        <button type="button" className="btn btn-sm btn-danger" onClick={hAcept}>Aceptar</button>
                    </Modal.Footer>
                </Modal>

                : null}
        </>
    );
}

function TablaImputacionesParte(props) {

    return (
        <>
            <div className="border p-1 mt-1 bg-light">
                <div className="d-flex flex-row justify-content-between">
                    <div>Lista de Imputaciones</div>
                </div>
            </div>
            <table className='table table-sm'>
                <thead className="thead-dark">
                    <tr>
                        <th style={{ width: '80px' }}>Fecha</th>
                        <th style={{ width: '80px' }}>Fase</th>
                        <th style={{ width: '100px' }}>Actividad</th>
                        {/*<th style={{ width: '80px' }}>Facturable</th>*/}
                        <th>Articulo</th>
                        <th style={{ width: '100px' }}>T&eacute;cnico</th>
                        <th>Horas</th>
                        <th style={{ width: "300px" }}>Observaciones</th>
                        <th style={{ width: "200px" }}>Observaciones Internas</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                {!props.parte.InformeImputaciones.lInforme.length ?
                    <tbody>
                        <tr><td colSpan="8">No hay imputaciones</td></tr>
                    </tbody>
                    :
                    props.parte.InformeImputaciones.lInforme.map((inf, index) =>
                        <tbody key={index}>
                            {!inf.lImputacion.length ? null :
                                inf.lImputacion.map(c =>
                                    <tr key={c.LineasPosicion}>
                                        <td>{c.FechaImputacionLcStr}</td>
                                        <td>{c.TipoFaseLc}</td>
                                        <td>{c.ActividadParteLc}</td>
                                        {/*<td style={{ textAlign: 'center' }}>{c.EsImputable}</td>*/}
                                        <td>{c.DescripcionArticulo}</td>
                                        <td>{c.NombreTecnicoAsignadoLc}</td>
                                        <td>{c.Unidades}</td>
                                        <td>{c.Observaciones}</td>
                                        <td>{c.ObservacionesInternas}</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                )}
                            {/* Total Parte */}
                            <tr style={{ backgroundColor: '#f8f9fa' }}>
                                <td colSpan="4">&nbsp;</td>
                                <td style={{ textAlign: 'right', fontSize: 14, fontWeight: 'bold' }} >Total Horas</td>
                                <td style={{ fontSize: 14, fontWeight: 'bold' }} >{inf.TotalHoras}</td>
                                <td >&nbsp;</td>
                            </tr>
                        </tbody>
                    )}

            </table>

        </>
    );
}