import React, { Component } from 'react';
import './Login.css';
import * as Api from 'components/Util/Api';
import ServiceInput from 'components/Util/ServiceInput';

// fontawesome
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoaded: false,
      isLoading: false,
      error: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hSendMail = this.hSendMail.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    let credentials = {
      user: this.state.username,
      pass: this.state.password
    };
    Api.post('/LoginUsuario', credentials).then((res) => {
      var usr = res.data;
      //console.log("res::", res);
      if (usr.Id && usr.Token) {
        //console.log("res.data::usr::", usr);
        sessionStorage.setItem('id', usr.Id);
        sessionStorage.setItem('token', usr.Token);
        this.setState({
          error: null,
          isLoading: false
        });
        //window.location = '/partes';
        window.location = '/';
      }
      else {
        //console.log("res.data::false");
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('token');
        this.setState({
          error: 'No autorizado',
          isLoading: false
        })
      }
    });
  }

  hSendMail() {
    this.setState({ isLoading: true });
    let credentials = {
      user: this.state.username,
      pass: this.state.password
    };
    Api.post('/RecordarClave', credentials).then((res) => {
      var usr = res.data;
      //console.log("res::", res);
      this.setState({
        error: usr.Mensaje,
        isLoading: false
      })
    });
  }

  //handleChange(field, evt) {
  //  const name = evt.name;
  //  this.setState({
  //    [name]: field.value
  //  });
  //}
  handleChange(name, value) {
    //console.log("Login::handleChange::", name, value);
    this.setState({
      [name]: value,
      error: '',
      isLoading: false
    })
  }


  ///////////////////
  componentDidMount() {
  }


  render() {
    //console.log("render::this.options::", this.options);
    return (
      <>
        <div id="login">
          <div className="text-center text-white mt-5"><img src="/logo.png" width="300px" alt="" /></div>
          <div className="container">
            <div id="login-row" className="row justify-content-center align-items-center">
              <div id="login-column" className="col-md-6">
                <div id="login-box" className="col-md-12">
                  <form id="login-form" className="form" onSubmit={this.handleSubmit}>
                    <div className="d-flex flex-column">
                      <h3 className="text-center text-primary m-4">Imputaciones</h3>
                      <ServiceInput className="form-control form-control-lg mb-3" onChange={this.handleChange} name="username" placeholder="email" />
                      <ServiceInput className="form-control form-control-lg mb-3" onChange={this.handleChange} name="password" type="password" placeholder="contrase&ntilde;a" />
                      <button className="btn btn-primary btn-lg btn-block" type="submit">Entrar&nbsp;{this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}</button>
                      <div className="text-center my-3">
                        <div className="link" onClick={() => this.hSendMail()}>&#191;Olvidaste tu contrase&ntilde;a?</div>
                      </div>
                      <div>
                        {this.state.error && <><div className="alert alert-danger" style={{ color: 'red' }}>{this.state.error}</div></>}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
  }
}

export default Login;

