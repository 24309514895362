import React from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'
import MenuTop from 'components/Menu/MenuTop';
import Partes from 'pages/Partes/Partes';
/*import Fases from 'pages/Fases/Fases';*/
import InformeImputaciones from 'pages/Imputaciones/InformeImputaciones';
import InformeMisImputaciones from 'pages/Imputaciones/InformeMisImputaciones';
import Clientes from 'pages/Clientes/Clientes';
import Home from 'pages/Home/Home';
import Usuario from 'pages/Usuario/Usuario';
import Valoraciones from 'pages/Valoraciones/Valoraciones';
import Facturacion from 'pages/Facturacion/Facturacion';
import Productividad from 'pages/Productividad/Productividad';
import Ofertas from 'pages/Ofertas/Ofertas';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { value: '', label: 'Partes', type: 'M' },
                { value: 'partes', label: 'Partes en curso', type: 'O' },
                //{ value: '', label: 'Fases', type: 'M' },
                //{ value: 'fases', label: 'Fases abiertas', type: 'O' },
                { value: '', label: 'Informes', type: 'M' },
                { value: 'informe-imputaciones', label: 'Imputaciones', type: 'O' },
                { value: 'informe-mis-imputaciones', label: 'Mis Imputaciones', type: 'O' },
                { value: 'clientes', label: 'Clientes', type: 'O' },
                { value: '', label: 'Valoraciones', type: 'M' },
                { value: 'valoraciones', label: 'Mis Valoraciones', type: 'O' },
                { value: '', label: 'Facturacion', type: 'M' },
                { value: 'informe-facturacion', label: 'Informe Facturacion', type: 'O' },
                { value: '', label: 'Productividad', type: 'M' },
                { value: 'informe-productividad', label: 'Inf Productividad', type: 'O' },
                { value: '', label: 'Ofertas', type: 'M' },
                { value: 'ofertas', label: 'Ofertas Pendientes', type: 'O' },
            ],
            optionSelected: ''
        }
        Main.contextType = AppContext;

        //
        this.setOption = this.setOption.bind(this);
        this.ContarValoraciones = this.ContarValoraciones.bind(this);
        this.ContarOfertas = this.ContarOfertas.bind(this);
    }

    componentDidMount() {
        // 
        this.setOption('partes'); // opcion inicial
        this.ContarValoraciones();
        this.ContarOfertas();
        /*console.log("this.props.Empleado", this.context.Empleado);*/
        
    }

    setOption(item) {
        //console.log("option::", item);

        this.setState({ optionSelected: item });
    }

    ContarValoraciones() {
        //console.log("this.context.Empleado.CodigoDepartamento::",this.context.Empleado.CodigoDepartamento)
        let data = {
            IdEmpleado: this.context.Empleado
        }
        // Valoracion
        api.post("/ContarValoracionesSinContacto", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({
                isLoading: false,
                numerovaloracionessincontacto: res.data
            });

               //console.log("res.data::", res.data);

        });
    }

    ContarOfertas() {

        let data = {
            tecnicoAsignado: this.context.Empleado.IdEmpleado
        }

        // Valoracion
        api.post("/ContarOfertasPendientes", data).then(res => {

            this.setState({
                isLoading: false,
                numeroofertaspendientes: res.data
            });

        });
    }

    render() {
        return (
            <>

                <MenuTop onSelect={this.setOption} />

                {/* Men� lateral*/}
                <div className="sidenav">
                    <div className="p-2">
                        {this.state.options.map((c, index) =>

                        (this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR'
                            || (this.context.Empleado.CodigoDepartamento == 'CONS' && (c.label != "Valoraciones" || c.label != "Mis Valoraciones"))
                                || (this.context.Empleado.CodigoDepartamento == 'EXTERN' && (c.label == "Valoraciones" || c.label == "Mis Valoraciones"
                                    || c.label == "Partes" || c.label == "Partes en curso"))
                            ) ?

                                <div key={index}
                                    className={(c.type === 'O' ? ' menu-option' : 'menu-menu')}>
                                    <div className={'my-1' + (c.type === 'O' ? ' ml-1' : '') + (c.value === this.state.optionSelected ? ' active' : '')}
                                        onClick={() => this.setOption(c.value)}>
                                        {c.label == "Mis Valoraciones" ? c.label + " (" + this.state.numerovaloracionessincontacto + ")" : c.label == "Ofertas Pendientes" ? c.label + " (" + this.state.numeroofertaspendientes + ")" : c.label }
                                    </div>
                                </div>
                                :
                                ""
                        )}
                    </div>
                </div>

                {/* Main */}
                <div className="main">
                    {this.state.optionSelected == "home" ? <Home /> : null}
                    {this.state.optionSelected == "usuario" ? <Usuario /> : null}
                    {this.state.optionSelected == "partes" ? <Partes /> : null}
                    {/*{this.state.optionSelected == "fases" ? <Fases /> : null}*/}
                    {this.state.optionSelected == "informe-imputaciones" ? <InformeImputaciones /> : null}
                    {this.state.optionSelected == "informe-mis-imputaciones" ? <InformeMisImputaciones /> : null}
                    {this.state.optionSelected == "clientes" ? <Clientes /> : null}
                    {this.state.optionSelected == "valoraciones" ? <Valoraciones /> : null}
                    {this.state.optionSelected == "informe-facturacion" ? <Facturacion /> : null}
                    {this.state.optionSelected == "informe-productividad" ? <Productividad /> : null}
                    {this.state.optionSelected == "ofertas" ? <Ofertas /> : null}
                </div>
               
            </>
        );
    }
}

export default Main;



