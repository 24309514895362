import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';


class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoaded: true
    }

    //console.log("this.props.item::", this.props.item);

    // Modal
    this.handleClose = this.handleCancel.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAcept = this.handleAcept.bind(this);
  }


  // Modal
  handleClose() {
    this.setState({ show: false });
  }
  handleAcept() {
    this.props.onAcept(this.props.item);
  }
  handleCancel() {
    this.setState({ show: false });
    this.props.onCancel(this.props.item);
  }
  
  render() {
    return (
      <>
        <Modal
          size="md"
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false} >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{this.props.message}</h5>
          </Modal.Body>
          <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-primary" onClick={this.handleCancel}>{this.props.cancelButtonText ? this.props.cancelButtonText : "Cancelar"}</button>&nbsp;
                    <button type="button" className="btn btn-sm btn-danger" onClick={this.handleAcept}>{this.props.confirmButtonText ? this.props.confirmButtonText : "Aceptar"}</button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}

export default ConfirmDialog;

