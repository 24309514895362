
import React from 'react'
import { AppContext } from '../../App'

//import CLock from './Clock';
import { Navbar, Nav } from 'react-bootstrap';
import { removeUserSession } from '../Util/Utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSignOutAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';



class MenuTop extends React.Component {

  constructor(props) {
    super(props);
    var spl = window.location.pathname.split('/');
    this.state = {
      opt: (spl.length >= 2 ? spl[1] : '')
      //user: this.props.sesion.empleado.nombre
    };
    MenuTop.contextType = AppContext;

    this.setOption = this.setOption.bind(this);
  }

  componentDidMount() {
    //console.log("componentDidMount::", this.context);
  }

  setOption(value) {
    //console.log("setOption::value::", value);
    this.props.onSelect(value);
  }

  //console.log("this.context::", this.context);

  render() {
    return (
      <AppContext.Consumer>
        {sesion =>
          <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
            <Navbar.Brand><div className="h5 cursorpointer" onClick={() => this.setOption('home')}>{sesion.Empleado.Nombre}</div></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Navbar.Text>
                 V.2.4
                </Navbar.Text>
                <Nav.Link onClick={() => this.setOption('usuario')} variant="link"><FontAwesomeIcon icon={faUserAlt} />&nbsp;Usuario</Nav.Link>
                <Nav.Link href="/" onClick={removeUserSession} variant="link"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;Salir</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        }
      </AppContext.Consumer>
    );
  }
}

export default MenuTop;

