import React, { Component } from 'react';
import { AppContext } from '../../App'

class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = { };
    Home.contextType = AppContext;
  }

  render () {
    return (
      <AppContext.Consumer>
        {sesion =>
          <div>
            <h5>Gesti&oacute;n de Partes e Imputaciones</h5>
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default Home;

//<div>
//  <h1>Gesti&oacute;n de Partes e Imputaciones</h1>
//</div>