import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    flexDirection: 'row',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableCol: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHead: {
    display: "table",
    borderStyle: "solid",
    backgroundColor: "#e0e0e0",
    minHeight: "13px",
    padding: 1,
    fontWeight: 'extrabold',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellCenter: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  tableCell: {
    alignContent: 'left',
    alignSelf: 'left',
  },
});

export default (props) => (
  <>
    <View style={styles.container}>

      <View style={styles.table}>
        {/* Articulo Parte*/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '100%' }]}>
            <View style={styles.tableCellCenter}>
              <Text>Articulo Parte Asistencia</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', minHeight: '60px' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.CodigoArticulo}   {props.parte.DescripcionArticulo}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', minHeight: '15px' }]}>
            <View style={styles.tableCell}>
              <Text>Observaciones:</Text>
            </View>
          </View>
        </View>

        {/* Objeto */}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '100%' }]}>
            <View style={styles.tableCellCenter}>
              <Text>Objeto de la Asistencia</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', minHeight: '60px' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
        </View>

        {/* Fechas */}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '100%' }]}>
            <View style={styles.tableCellCenter}>
              <Text>Fechas de la Asistencia</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', minHeight: '18px' }]}>
            <View style={styles.tableCell}>
              <Text>{props.data.FechasAsistencia}</Text>
            </View>
          </View>
        </View>

        {/* Trabajos realizados */}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '100%' }]}>
            <View style={styles.tableCellCenter}>
              <Text>Trabajos realizados</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', minHeight: '70px' }]}>
            <View style={styles.tableCell}>
              <Text>{props.data.TrabajosRealizados}</Text>
            </View>
          </View>
        </View>

      </View>

    </View>
  </>
);

