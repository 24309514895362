import React from 'react';

import { Link, Image, Text, View, StyleSheet } from '@react-pdf/renderer';

const logo = "logo_pq.png";

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 6,
    alignSelf: 'flex-center',
    justifySelf: 'flex-center',
  },
  rigthColumn: {
    flexDirection: 'column',
    flexGrow: 6,
    alignSelf: 'flex-center',
    justifySelf: 'flex-center',
  },
  text: {
    fontSize: 10,
    color: '#333',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    lineHeight: 1.3,
  },
  textBold: {
    fontSize: 10,
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    lineHeight: 1.3,
  },
});

export default () => (
  <View style={styles.container}>
    <View style={styles.leftColumn}>
      <Image
        style={{ width: 180, height: 60, padding: 5 }}
        source={logo}
      />
    </View>
    <View style={styles.rigthColumn}>
      <Text style={styles.textBold}>ESMA DATA, S.L.      CIF: B63739247</Text>
      <Text style={styles.text}>C/. Diputación, 238 08007 BARCELONA</Text>
      <Text style={styles.text}>Télefono 93 342 84 80</Text>
      <Text style={styles.text}>s200.administracion@esmadata.com</Text>
    </View>
  </View>
);