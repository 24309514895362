import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from 'App'

import Modal from 'react-bootstrap/Modal';
import ConfirmDialog from 'components/Util/ConfirmDialog';
import CampoFicha from 'components/Util/CampoFicha';
import ServiceSelect from 'components/Util/ServiceSelect';
import ServiceDate from 'components/Util/ServiceDate';
import ServiceInput from 'components/Util/ServiceInput';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCog, faEdit, faFilePdf, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import ModalImprimirParteAsistenciaImputacion from 'pages/Imputaciones/ModalImprimirParteAsistenciaImputacion';


class ModalImputacion extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            firstday: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
            lastday: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)
        }
        ModalImputacion.contextType = AppContext;

        if (this.props.imputacion) {
            this.state = {
                ...this.state, ...props.imputacion,
                defaultday: this.props.imputacion.AnoFechaImputacionLc ? new Date(this.props.imputacion.AnoFechaImputacionLc, this.props.imputacion.MesFechaImputacionLc - 1, this.props.imputacion.DiaFechaImputacionLc) : new Date(),
                showModalImputacion: true
            }
        }
        else {
            this.state = {
                ...this.state,
                FechaImputacionLc: new Date(),
                Unidades: 0,
                CodigoTipoFaseLc: '',
                CodigoActividadParteLc: '',
                TecnicoAsignadoLc: '',
                NombreTecnicoAsignadoLc: '',
                Observaciones: '',
                ObservacionesInternas: '',
                defaultday: new Date(),
                showImprimirParteAsistencia: false,
                ParteAsistenciaDescargado: 0,
                EmailHorasSuperadasFase: false,
                showDialogParteAsistencia: false,
                showModalImputacion: true,
                cerrarventana: true,
                imp: []
            }
        }

        this.actividades = [];

        //
        this.handleChange = this.handleChange.bind(this);
        // Modal
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        //
        this.validate = this.validate.bind(this);
        this.formatDate = this.formatDate.bind(this);

        this.hShowImprimirParteAsistencia = this.hShowImprimirParteAsistencia.bind(this);
        this.hCloseImprimirParteAsistencia = this.hCloseImprimirParteAsistencia.bind(this);
        this.hShowDialogParteAsistencia = this.hShowDialogParteAsistencia.bind(this);
        this.hCloseDialogParteAsistencia = this.hCloseDialogParteAsistencia.bind(this);
    }

    componentDidMount() {
        //console.log("context::", this.context);
        //console.log("this.props.imputacion::", this.props.imputacion);
        if (this.props.imputacion) {
            this.setState({
                TecnicoAsignadoLc: this.props.imputacion.TecnicoAsignadoLc ? this.props.imputacion.TecnicoAsignadoLc : this.props.parte.TecnicoAsignadoLc,
                NombreTecnicoAsignadoLc: this.props.imputacion.NombreTecnicoAsignadoLc ? this.props.imputacion.NombreTecnicoAsignadoLc : this.props.parte.NombreTecnicoAsignadoLc,
                showModalImputacion: this.props.show
            });
        }
        else {
            this.setState({
                TecnicoAsignadoLc: this.context.Empleado.IdEmpleado ? this.context.Empleado.IdEmpleado : this.props.parte.TecnicoAsignadoLc,
                NombreTecnicoAsignadoLc: this.context.Empleado.Nombre ? this.context.Empleado.Nombre : this.props.parte.NombreTecnicoAsignadoLc,
                showModalImputacion: this.props.show
            });
        }
    }

    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    handleChange(name, value) {
        //console.log("ModalImputacion::name::", name);
        //console.log("ModalImputacion::value::", value);
        this.setState({
            [name]: value
        });
    }

    // Modal
    handleClose() {
        this.props.onClose();
    }

    handleSave(cerrarventana = true) {
        //console.log("handleSave::this.state::", this.state);
        //console.log("handleSave::this.props.parte::", this.props.parte);

        this.setState({
            cerrarventana: cerrarventana
        });

        if (this.validate()) {
            // recuperar los datos básicos
            api.post("/NuevaImputacion", this.props.parte).then(res => {
                //console.log("NuevaImputacion::res.data::", res.data);

                var imp = res.data;

                if (!this.state.LineasPosicion)
                    this.setState({
                        LineasPosicion: imp.LineasPosicion
                    });

                imp.FechaImputacionLcStr = this.formatDate(this.state.FechaImputacionLc);
                imp.FechaImputacionLc = this.state.FechaImputacionLc;
                imp.Unidades = this.state.Unidades;
                imp.CodigoTipoFaseLc = this.state.CodigoTipoFaseLc;
                imp.CodigoActividadParteLc = this.state.CodigoActividadParteLc;
                imp.TecnicoAsignadoLc = this.state.TecnicoAsignadoLc;
                imp.NombreTecnicoAsignadoLc = this.state.NombreTecnicoAsignadoLc;
                imp.Observaciones = this.state.Observaciones;
                imp.ObservacionesInternas = this.state.ObservacionesInternas;
                imp.LineasPosicion = this.state.LineasPosicion;
                imp.ParteAsistenciaDescargado = this.state.ParteAsistenciaDescargado;
                imp.EmailHorasSuperadasFase = cerrarventana;

                //Grabar la imputación
                api.post("/GrabarImputacion", imp).then(res => {
                    //console.log("GrabarImputacion::res.data::", res.data);
                    var resultado = res.data;

                    if (resultado.Correcto) {

                        if (resultado.LineasPosicion)
                            this.setState({
                                LineasPosicion: resultado.LineasPosicion
                            });

                    }
                    if (this.state.cerrarventana)
                      this.props.onClose();
                });

            });

            //if (cerrarventana)
            //    this.props.onClose();
        }
    }


    validate() {
        var valid = false;

        if (!this.state.Unidades) {
            this.setState({ msgError: 'Debe informar las horas' });
        }
        else if (!this.state.CodigoTipoFaseLc) {
            this.setState({ msgError: 'Debe informar la fase' });
        }
        else if (!this.state.CodigoActividadParteLc) {
            this.setState({ msgError: 'Debe informar la actividad' });
        }
        else if (!this.state.TecnicoAsignadoLc) {
            this.setState({ msgError: 'Debe informar el tecnico asignado' });
        }
        else if (!this.state.Observaciones) {
            this.setState({ msgError: 'Debe informar las observaciones' });
        }
        else {
            valid = true;
        }

        return valid;
    }

    // *************************
    // Modal Imprimir parte asistencia
    hShowImprimirParteAsistencia(item) {
        this.setState({
            showImprimirParteAsistencia: true,
            showDialogParteAsistencia: false,
            itemSelected: item,
            showModalImputacion: false,
        });
        
    }

    hCloseImprimirParteAsistencia() {
        this.setState({
            showImprimirParteAsistencia: false
        });
    }

  
    hShowDialogParteAsistencia() {

        this.handleSave(false)

        this.setState({
            showDialogParteAsistencia: true
        });

    }

    hCloseDialogParteAsistencia() {

        this.handleSave(true);
        this.setState({
            showDialogParteAsistencia: false
        });
    }

    // *************************

    /////////////////////////////

    handleParteDescargado = () => {

        this.setState({
            ParteAsistenciaDescargado: 1
        });

        this.handleSave(false);

    }

    handleParteClose = () => {

        this.handleClose();
    }

    

    render() {

        if (this.state.isLoading) {
            return <div>Loading...</div>
        }
        else {
            return (
                <>
                    <Modal
                        size="xl"
                        show={this.state.showModalImputacion}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Imputaciones
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<div>state::{JSON.stringify(this.state)}</div>*/}
                            {/*<div>imputacion::{JSON.stringify(this.props.imputacion)}</div>*/}
                            {/*<div>defaultday::{this.state.defaultday.toString()}</div>*/}
                            <div className="row">
                                <div className="col col-lg-2">
                                    <CampoFicha label="Parte" value={this.props.parte.NumeroParteLc.toString()} />
                                </div>
                                <div className="col">
                                    <CampoFicha label="Cliente" value={this.props.parte.NombreCliente} />
                                </div>
                                <div className="col">
                                    <CampoFicha label="Articulo" value={this.props.parte.DescripcionArticulo} />
                                </div>
                            </div>
                            <div className="row">
                                {/* Fecha */}
                                <div className="col col-lg-3">
                                    {/*<div>Modo::{this.props.modo}</div>*/}
                                    <ServiceDate onChange={this.handleChange}
                                        name="FechaImputacionLc"
                                        title="Fecha"
                                        default={this.state.defaultday}
                                        mindate={this.state.firstday}
                                        maxdate={this.state.lastday}
                                        inline={true}
                                        disabled={this.props.mode == 'update' ? true : false}
                                    />
                                </div>
                                <div className="col col-lg-9">
                                    <div className="row">
                                        {/* Articulo */}
                                        <div className="col col-lg-12">
                                            <div className="d-flex flex-column">
                                                <div className="control-label">Observaciones parte</div>
                                                <textarea className="form-control" rows="3" value={this.props.parte.Observaciones ? this.props.parte.Observaciones : ''} disabled />
                                            </div>
                                        </div>
                                        {/* Técnico */}
                                        <div className="col col-lg-12">
                                            {this.context.EsJefeProyecto ?
                                                <ServiceSelect onChange={this.handleChange}
                                                    name="TecnicoAsignadoLc"
                                                    service="ListaTecnicosSelect"
                                                    title="T&eacute;cnico asignado"
                                                    default={this.state.TecnicoAsignadoLc ? { value: this.state.TecnicoAsignadoLc, label: this.state.NombreTecnicoAsignadoLc } : null} />
                                                :
                                                <CampoFicha label="T&eacute;cnico asignado" value={this.state.NombreTecnicoAsignadoLc} />
                                            }
                                        </div>
                                        {/* Unidades */}
                                        <div className="col col-lg-2">
                                            <ServiceInput onChange={this.handleChange}
                                                name="Unidades"
                                                default={this.state.Unidades}
                                                title="Horas"
                                                placeholder="Horas"
                                                type="number"
                                                autofocus={true} />
                                        </div>
                                        {/* Fase */}
                                        <div className="col col-lg-5">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="CodigoTipoFaseLc"
                                                list={this.props.parte.lFaseParteSelect}
                                                title="Fase"
                                                default={this.state.CodigoTipoFaseLc ? { value: this.state.CodigoTipoFaseLc, label: this.state.CodigoTipoFaseLc + "-" + this.state.TipoFaseLc } : null} />
                                        </div>
                                        {/* Actividad */}
                                        <div className="col col-lg-5">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="CodigoActividadParteLc"
                                                service="ListaActividadesSelect"
                                                title="Actividad"
                                                default={this.state.CodigoActividadParteLc ? { value: this.state.CodigoActividadParteLc, label: this.state.CodigoActividadParteLc + "-" + this.state.ActividadParteLc } : null} />
                                        </div>
                                        {/*<div className="col-lg-3 mt-1">*/}
                                        {/* Parte asistencia */}
                                        {/*    <CampoFicha label="Parte de Asistencia Descargado" value={this.state.ParteAsistenciaDescargado == 1 ? "SI":"NO"} />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ServiceInput onChange={this.handleChange}
                                        name="Observaciones"
                                        title="Observaciones imputaci&oacute;n"
                                        placeholder="Observaciones"
                                        type="textarea"
                                        rows={4}
                                        default={this.state.Observaciones} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ServiceInput onChange={this.handleChange}
                                        name="ObservacionesInternas"
                                        title="Observaciones Internas"
                                        placeholder="Observaciones Internas"
                                        type="textarea"
                                        rows={4}
                                        default={this.state.ObservacionesInternas} />
                                </div>
                            </div>
                    
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.msgError ? <div className="alert alert-danger">{this.state.msgError}</div> : null}
                            {this.state.ParteAsistenciaDescargado == 1 ? <button type="button" className="btn btn-sm btn-info" onClick={() => this.hShowImprimirParteAsistencia(this.props.parte)}><span className="btn btn-xs btn-outline-light"><FontAwesomeIcon icon={faFilePdf} /></span>&nbsp;Parte de Asistencia</button> : null}
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                            {/*<button type="button" className="btn btn-sm btn-primary" onClick={this.state.ParteAsistenciaDescargado == 0 ? this.hShowDialogParteAsistencia : this.handleSave()}>Guardar</button>*/}
                            {/*{(this.state.ParteAsistenciaDescargado == 0) ?*/}
                            {/*    <button type="button" className="btn btn-sm btn-primary" onClick={this.hShowDialogParteAsistencia}>Guardar</button>*/}
                            {/*    :*/}
                            {/*    <button type="button" className="btn btn-sm btn-primary" onClick={() => this.handleSave(true)}>Guardar</button>*/}
                            {/*}*/}
                            <button type="button" className="btn btn-sm btn-primary" onClick={this.hShowDialogParteAsistencia}>Guardar</button>
          
                        </Modal.Footer>

                    </Modal>

                    {/* Modal imprimir parte asistencia */}
                    {(this.state.showImprimirParteAsistencia) ?

                        <ModalImprimirParteAsistenciaImputacion
                            show={this.state.showImprimirParteAsistencia}
                            parte={this.state.itemSelected}
                            observaciones={this.state.Observaciones}
                            FechaImputacion={this.formatDate(this.state.FechaImputacionLc)}
                            onClose={this.hCloseImprimirParteAsistencia}
                            handleParteDescargado={this.handleParteDescargado}
                            handleParteClose={this.handleParteClose}
                        /> : null}

                    {/* ImprimirParteAsistencia */}
                    {(this.state.showDialogParteAsistencia) ?
                        <ConfirmDialog
                            show={this.state.showDialogParteAsistencia}
                            title="Imprimir parte de asistencia"
                            message="Desea imprimir el parte de asistencia de esta imputaci&oacute;n?"
                            onAcept={() => this.hShowImprimirParteAsistencia(this.props.parte)}
                            onCancel={this.hCloseDialogParteAsistencia}
                            cancelButtonText='&nbsp;&nbsp;No&nbsp;&nbsp;'
                            confirmButtonText='&nbsp;&nbsp;Si&nbsp;&nbsp;'
                        />
                        :
                        null}
                    
                </>
            );
        }
    }
}

export default ModalImputacion;

