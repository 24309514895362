import * as Api from '../Util/Api';
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

class ServiceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      selectedOption: null,
      default: this.props.default ? this.props.default : null,
      options: [{ value: '', label: '' }]
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (data) => {
    //console.log("ServiceSelect::data::", data);
    if (data.value)
      this.props.onChange(this.props.name, data.value);
    else if (data)
      this.props.onChange(this.props.name, data);
    else
      this.props.onChange(this.props.name, '');
  }

  ///////////////////
  componentDidMount() {
    //console.log("ServiceSelect:DidMount::props::", this.props);
    // get Tipo Parte
    if (this.props.service) {
      this.setState({ isLoaded: false });
      var srv = this.props.service.charAt(0) == '/' ? this.props.service : '/' + this.props.service;
      Api.post(srv, (this.props.servicedata ? this.props.servicedata : {})).then(res => {
        const data = res.data;
        var opt = data;
        if (this.props.first)
          opt.unshift(this.props.first);
        this.setState({ isLoaded: true, options: opt });
      });
    }
    else if (this.props.list) {
      this.setState({ isLoaded: true });
      //console.log("ServiceSelect:DidMount::list::", this.props.list);
      var opt = this.props.list;
      if (this.props.first)
        opt.unshift(this.props.first);
      this.setState({ isLoaded: true, options: opt });
    }
  }


  render() {
    //console.log("render::this.props.default::", this.props.default);

    const styles = {
      container: (provided) => ({
        ...provided,
        minHeight: '1px',
        textAlign: 'left',
        padding: 0,
      }),
      option: (provided, state) => ({
        ...provided,
        padding: 6,
        fontSize: 12,
      }),
      control: (base) => ({
        ...base,
        minHeight: 25,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    };

    if (!this.state.isLoaded) {
      return (
        <>
          <Form.Group>
            {this.props.title && <Form.Label>{this.props.title}</Form.Label>}
            <div className="form-control form-control-sm disabled">No hay datos...</div>
          </Form.Group>
        </>
      );
    }
    else {
      return (
        <div className="d-flex flex-column">
          {this.props.title && <div>{this.props.title}</div>}
          <Select
            styles={styles}
            defaultValue = { this.state.default ? this.state.default : (this.props.selectedfirst && this.state.options ? this.state.options[0] : null) }
            isMulti={this.props.multiple ? this.props.multiple : false}
            name={this.props.name}
            options={this.state.options}
            className={this.props.className ? this.props.className : "select-sm"}
            placeholder={this.props.placeholder ? this.props.placeholder : this.props.title}
            onChange={this.handleChange}
            isDisabled={this.props.disabled ? this.props.disabled : false}
          />
        </div>
      );
    }
  }
}

export default ServiceSelect;




