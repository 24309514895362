import React, { Component } from 'react';

class CampoFicha extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    return (
      <div className="d-flex flex-column">
        <div>{this.props.label}</div>
            <div className="form-control form-control-sm disabled ellipsis" >{this.props.value}</div>
      </div>
    );
  }
}

export default CampoFicha;

