//import react from 'react';
import * as api from 'components/Util/Api';
/* remove user session */
export const removeUserSession = () => {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');

    api.post('/Logout').then((res) => {

    });
}