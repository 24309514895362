import React, { Component } from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

class ServiceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: this.props.default ? this.props.default : false,
    }

    this.setBool = this.setBool.bind(this);
  }

  setBool(value) {
    //console.log("value::", value);
    this.setState({
      value: value
    });
    this.props.onChange(this.props.name, value);
  }
  
  ///////////////////
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    else {
      return (
        <div className="d-flex flex-column">
          {this.props.title && <div>{this.props.title}</div>}
          <ButtonGroup size={this.props.size ? this.props.size : ""}>
            <Button onClick={() => this.setBool(true)} variant=""
              className={`btn ${this.state.value ? " btn-primary" : " btn-outline-secondary"}`}>Si
            </Button>
            <Button onClick={() => this.setBool(false)} variant=""
              className={`btn ${!this.state.value ? " btn-primary" : " btn-outline-secondary"}`}>No
            </Button>
          </ButtonGroup>
        </div>
      );
    }
  }
}

export default ServiceInput;



