import React, { Component } from 'react';
import * as api from 'components/Util/Api';

// fontawesome
import BuscarClientes from './BuscarClientes';
import FichaCliente from '../../components/Cliente/FichaCliente';

class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      listaclientes: [],
      firstday: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      lastday: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    }

    // 
    this.hSelCliente = this.hSelCliente.bind(this);
  }


  //////////////////////////
  componentDidMount() {
    //this._isMounted = true;
  }


  hSelCliente(item) {
    //console.log("hSelCliente::item::", item);

    this.setState({ isLoading: true });
    if (item) {
      let data = {
        CodigoCliente: item.CodigoCliente
      }
      // Cliente
      api.post("/LeerCliente", data).then(res => {
        //console.log("LeerCliente::res.data::", res.data);
        this.setState({
          isLoading: false,
          cliente: res.data
        });
      });
    }
    
  }

  render() {

    if (this.state.isLoading) {
      return <div>Loading....</div>
    }
    else {
      return (
        <>

          <BuscarClientes onSelect={this.hSelCliente} />

          {this.state.cliente ?
            <FichaCliente cliente={this.state.cliente} />
            : null
          }

        </>
      );
    }
  }
}

export default Clientes;

