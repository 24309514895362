import React, { Component } from 'react';
import CampoFicha from 'components/Util/CampoFicha';


class FichaCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true
    }
  }

  componentDidMount() {
    //console.log("DidMount::FichaCliente::cliente::", this.props.cliente);
  }

  render() {
    if (!this.props.cliente) {
      return <div></div>
    }
    else {
      return (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col col-lg-2">
                  <CampoFicha label="Codigo" value={this.props.cliente.CodigoCliente} />
                </div>
                <div className="col col-lg-8">
                  <CampoFicha label="Razon Social" value={this.props.cliente.RazonSocial} />
                </div>
                <div className="col col-lg-2">
                  <CampoFicha label="CIF/DNI" value={this.props.cliente.CifDni} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-2">
                  <CampoFicha label="Provincia" value={this.props.cliente.Provincia} />
                </div>
                <div className="col col-lg-10">
                  <CampoFicha label="Municipio" value={this.props.cliente.Municipio} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-2">
                  <CampoFicha label="Cod Postal" value={this.props.cliente.CodigoPostal} />
                </div>
                <div className="col col-lg-10">
                  <CampoFicha label="Domicilio" value={this.props.cliente.Domicilio} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-12">
                  <CampoFicha label="Persona Cliente" value={this.props.cliente.PersonaClienteLc} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-4">
                  <CampoFicha label="Contacto 1" value={this.props.cliente.Nombre1 + ( this.props.cliente.Cargo1 ? ' (' + this.props.cliente.Cargo1 + ')' : '' )} />
                </div>
                <div className="col col-lg-4">
                  <CampoFicha label="Contacto 2" value={this.props.cliente.Nombre2 + (this.props.cliente.Cargo2 ? ' (' + this.props.cliente.Cargo2 + ')' : '')} />
                </div>
                <div className="col col-lg-4">
                  <CampoFicha label="Contacto 3" value={this.props.cliente.Nombre3 + (this.props.cliente.Cargo3 ? ' (' + this.props.cliente.Cargo3 + ')' : '')} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-4">
                  <CampoFicha label="Teléfono" value={this.props.cliente.Telefono} />
                </div>
                <div className="col col-lg-4">
                  <CampoFicha label="Teléfono2" value={this.props.cliente.Telefono2} />
                </div>
                <div className="col col-lg-4">
                  <CampoFicha label="Teléfono3" value={this.props.cliente.Telefono3} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col col-lg-6">
                  <CampoFicha label="Email1" value={this.props.cliente.Email1} />
                </div>
                <div className="col col-lg-6">
                  <CampoFicha label="Email2" value={this.props.cliente.Email2} />
                </div>
              </div>
            </div>
          </div>

        </>
      );
    }
  }
}

export default FichaCliente;

