import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import ServiceSelect from 'components/Util/ServiceSelect';

import { AppContext } from 'App'

import { faSearch } from '@fortawesome/free-solid-svg-icons';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Facturacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            CodigoCliente: '',
            tecnicoAsignado: '',
            facturacion: [],
            year: new Date().getFullYear(),
            Usuario: props.sesion,
        }
        Facturacion.contextType = AppContext;
        //

        this.hSelFacturacion = this.hSelFacturacion.bind(this);
        this.hChangeFacturacion = this.hChangeFacturacion.bind(this);

    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
        this.state.year = new Date().getFullYear();
        this.hSelFacturacion(this.state.year);
/*        console.log(this.state.year);*/
    }

    ////////////////////


    hSelFacturacion(year) {


        let data = {
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : this.context.Empleado.IdEmpleado,
            year: year != undefined ? year : this.state.year != undefined ? this.state.year : new Date().getFullYear()
        }

        api.post("/Facturacion", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({ facturacion: res.data, isLoading: false });
           /* console.log(this.state.facturacion);*/
        });
    }

    hChangeFacturacion(name, value) {
        //console.log("hChangeFacturacion::", name + "" + value);
        this.setState({
            [name]: value,
            showDetalleValoracion: false
        });
    }

    
    render() {
        return (
            <>
                {/* Lista de valoraciones */}
                <div>
                    <div className="border p-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Facturacion</div>
                        </div>
                    </div>
                </div>

                {/* Buscar */}
                    <div className="border pt-2 pl-2 pb-3">
                    <div className="d-flex flex-row justify-content-start">
                        {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChangeFacturacion} name="tecnicoAsignado" service="ListaTecnicosNoExtSelect" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={this.state.TecnicoAsignadoLc ? { value: this.state.TecnicoAsignadoLc, label: this.state.NombreTecnicoAsignadoLc } : { value: this.context.Empleado.IdEmpleado, label: this.context.Empleado.Nombre }} />
                            </div>
                        ) : ""}
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChangeFacturacion} name="year" service="ListaYear" title="A&ntilde;o" default={{ value: this.state.year, label: this.state.year.toString() }} />
                            </div>
                        
                        <div className="w-10 px-1">
                            <div className="d-flex flex-column">
                                <div>&nbsp;</div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.hSelFacturacion()}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                 
                <div className="border p-2 pl-2">
                <table className='table table-sm table-bordered mt-4 col-4'>
                        <thead className="thead-dark">
                        <tr>
                            <th>Mes/A&ntilde;o</th>
                            <th>Importe de Ventas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.state.facturacion.length ? null :
                            this.state.facturacion.map(item =>
                                <tr className="cursorpointer" key={item.NMes}>

                                    {item.Mes == 'TOTAL' ? (
                                        <td style={{ fontWeight: 'bold' }}>{item.Mes}</td>
                                    ) : <td>{item.Mes}</td>}

                                     {item.Mes == 'TOTAL'? (
                                        <td style={{ fontWeight: 'bold' }}>{item.ImporteVentasFmt}</td>
                                    ) : <td>{item.ImporteVentasFmt}</td>}

                                </tr>
                            )}
                        {this.state.facturacion.length == 0 &&
                            <tr><td colSpan="3">No hay datos para la consulta</td></tr>
                        }
                    </tbody>
                    </table>
                </div>
            </>
        );
    }


}

export default Facturacion;


