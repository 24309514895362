import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
  table: {
    display: "table",
    width: "auto",
    borderWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableCol: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 0,
  },
  tableCell: {
    alignContent: 'left',
    alignSelf: 'left',
  },
  tableColBorder: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
  },
  tableColBorderTopBottom: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  text: {
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  textBold: {
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'bold',
  }
});

export default (props) => (
  <>
    <View style={styles.container}>

      <View style={[styles.table, { borderWidth: 0, marginTop: 3, marginBottom: 3 }]}>
        {/**/}
        <InspeccionFinal parte={props.parte} data={props.data} />
      </View>

    </View>
  </>
);

function InspeccionFinal(props) {
  return (
    <View style={styles.tableRow}>
      <View style={[styles.tableColBorder, { width: '15px' }]}>
        <View style={styles.tableCell}>
          {props.data.InspeccionFinal ? 
            <Text> X </Text>
            :
            <Text></Text>
            }
        </View>
      </View>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text style={styles.textBold}>Inspección final</Text>
        </View>
      </View>
      <View style={[styles.tableColBorderTopBottom, { width: '82%' }]}>
        <View style={styles.tableCell}>
          <Text>{props.data.InspeccionFinalTexto}</Text>
        </View>
      </View>
    </View>
  );
}

