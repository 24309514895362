import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'

import CampoFicha from 'components/Util/CampoFicha';
import ServiceInput from 'components/Util/ServiceInput';

class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajeResultado: null
    };
    Usuario.contextType = AppContext;

    // return
    this.handleChange = this.handleChange.bind(this);
    this.cambiarPassword = this.cambiarPassword.bind(this);
    this.validarPassword = this.validarPassword.bind(this);
  }

  handleChange(name, value) {
    //console.log("Login::handleChange::", name, value);
    this.setState({
      [name]: value
    });
  }

  cambiarPassword() {
    //console.log("cambiarPassword::", this.state.claveAnterior, this.state.claveNueva, this.state.claveRepite);
    this.setState({ mensajeResultado: null });
    if (this.validarPassword()) {
      let data = {
        Email1: this.context.Empleado.Email1,
        ClaveAnterior: this.state.claveAnterior,
        ClaveNueva: this.state.claveNueva
      };
      api.post('/CambiarClave', data).then((res) => {
        var resultado = res.data;
        //console.log("res::", res);
        this.setState({ mensajeResultado: resultado.Mensaje });
      });
    }
  }

  validarPassword() {

    if (this.state.claveNueva != this.state.claveRepite) {
      this.setState({ mensajeResultado: 'Las contrase�as no coinciden' });
      return false;
    }

    if (this.state.claveNueva.length < 4 || this.state.claveNueva.length > 15 ) {
      this.setState({ mensajeResultado: 'La contrase�a debe contener entre 4 y 15 caracteres' });
      return false;
    }

    return true;

  }

  render () {
    return (
      <AppContext.Consumer>
        {sesion =>
          <div>
            <h5>Usuario</h5>
            <div className="card">
              <div className="card-header">
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col col-lg-2">
                    <CampoFicha label="Id" value={sesion.Empleado.IdEmpleado} />
                  </div>
                  <div className="col col-lg-5">
                    <CampoFicha label="Nombre" value={sesion.Empleado.Nombre} />
                  </div>
                  <div className="col col-lg-3">
                    <CampoFicha label="Email" value={sesion.Empleado.Email1} />
                  </div>
                  <div className="col col-lg-2">
                    <CampoFicha label="Categor&iacute;a" value={sesion.Empleado.CategoriaEmpleadoLc} />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-3">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <ServiceInput className="form-control form-control-sm" onChange={this.handleChange} title="Contrase&ntilde;a anterior" name="claveAnterior" type="password" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <ServiceInput className="form-control form-control-sm" onChange={this.handleChange} title="Nueva contrase&ntilde;a" name="claveNueva" type="password" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <ServiceInput className="form-control form-control-sm" onChange={this.handleChange} title="Repite contrase&ntilde;a" name="claveRepite" type="password" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button className="btn btn-primary btn-md" type="button" onClick={() => this.cambiarPassword()} >Cambiar contrase&ntilde;a</button>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-12">
                          {this.state.mensajeResultado ?
                            <div className="alert alert-danger">{this.state.mensajeResultado}</div>
                            : null
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default Usuario;

//<div>
//  <h1>Gesti&oacute;n de Partes e Imputaciones</h1>
//</div>