import axios from 'axios';

export const post = (service, d) => {
  var ds = d ? d : {};
  ds.IdEmpleado = sessionStorage.getItem('id');
  //ds.token = sessionStorage.getItem('token');
  //console.log("api.post::data::", ds);
  return axios(service, {
    method: 'post',
    headers: {
      'content-type': 'application/json', 
    },
    data: ds,
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const get = (service, d) => {
  var ds = d ? d : {};
  ds.IdEmpleado = sessionStorage.getItem('id');
  //ds.token = sessionStorage.getItem('token');
  //console.log("api.get::data::", ds);
  return axios(service, {
    method: 'get',
    headers: {
      'content-type': 'application/json', 
    },
    data: ds,
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};