import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    flex: 1,
    fontSize: 8,
    fontWeight: 'extrabold',
  },
  table: {
    display: "table",
    width: "auto",
    borderWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableCol: {
    display: "table",
    minHeight: "13px",
    padding: 1,
    borderWidth: 0,
  },
  tableColBorder: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
  },
  tableCell: {
    alignContent: 'left',
    alignSelf: 'left',
  },
  tableCellCenter: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  text: {
    paddingLeft: '10px',
    fontWeight: 'extrabold',
  }
});

export default (props) => (
  <>
    <View style={styles.container}>

      <View style={styles.table}>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '50px' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
          <View style={[styles.tableColBorder, { width: '13px' }]}>
            <View style={styles.tableCellCenter}>
              <Text>X</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '160px' }]}>
            <View style={styles.tableCell}>
              <Text style={styles.text}>Cargo según Oferta</Text>
            </View>
          </View>
          <View style={[styles.tableColBorder, { width: '13px' }]}>
            <View style={styles.tableCellCenter}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '160px' }]}>
            <View style={styles.tableCell}>
              <Text style={styles.text}>Con Cargo</Text>
            </View>
          </View>
          <View style={[styles.tableColBorder, { width: '13px' }]}>
            <View style={styles.tableCellCenter}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '160px' }]}>
            <View style={styles.tableCell}>
              <Text style={styles.text}>Sin Cargo</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '50px' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
          <View style={[styles.tableColBorder, { width: '13px' }]}>
            <View style={styles.tableCellCenter}>
              {props.data.InspeccionFinal ?
                <Text> X </Text>
                :
                <Text></Text>
              }
            </View>
          </View>
          <View style={[styles.tableCol, { width: '160px' }]}>
            <View style={styles.tableCell}>
              <Text style={styles.text}>Inpección Final</Text>
            </View>
          </View>
          <View style={[styles.tableColBorder, { width: '13px' }]}>
            <View style={styles.tableCellCenter}>
              {props.data.CerrarParte ?
                <Text> X </Text>
                :
                <Text></Text>
              }
            </View>
          </View>
          <View style={[styles.tableCol, { width: '340px' }]}>
            <View style={styles.tableCell}>
              <Text style={styles.text}>Cerrar Parte</Text>
            </View>
          </View>
        </View>
      </View>

    </View>
  </>
);


