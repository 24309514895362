import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import Modal from 'react-bootstrap/Modal';

import FichaCliente from 'components/Cliente/FichaCliente';

import CampoFicha from 'components/Util/CampoFicha';


class ModalCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true
    }

    //console.log("this.props.item::", this.props.item);

    // Modal
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    let data = {
      CodigoCliente: this.props.item.CodigoCliente
    }
    // Cliente
    api.post("/LeerCliente", data).then(res => {
      //console.log("LeerCliente::res.data::", res.data);
      this.setState({
        isLoading: false,
        cliente: res.data
      });
    });
  }

  // Modal
  handleClose() {
    this.props.onClose();
    this.setState({
      showModal: false
    });
  }
  

  render() {
    //if (!this.props.listapartes) {
    //  return <div>No hay datos para la consulta</div>
    //}
    //else {
    return (
      <>
        <Modal
          size="xl"
          show={this.props.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false} >
          <Modal.Header closeButton>
            <Modal.Title>
              Ficha Cliente (N.Parte:&nbsp;{this.props.item.NumeroParteLc})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.cliente ?
              <FichaCliente cliente={this.state.cliente} />
              : null
            }
          </Modal.Body>
          <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>
              Cerrar
              </button>
          </Modal.Footer>
        </Modal>

      </>
    );
    //}
  }
}

export default ModalCliente;

