import React, { Component, useState, useEffect } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'

import ConfirmDialog from 'components/Util/ConfirmDialog';

import Modal from 'react-bootstrap/Modal';

import ServiceInput from 'components/Util/ServiceInput';
import ServiceBool from 'components/Util/ServiceBool';
import CampoFicha from 'components/Util/CampoFicha';

class ModalOferta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.oferta,
            isLoading: false
        }
        ModalOferta.contextType = AppContext;

        // Modal
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this);


    }

    ///
    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }


    // Modal
    handleClose() {
        this.props.onClose();
        this.setState({
            show: false
        });
    }

    handleSave() {
   /*     console.log("handleSave::this.state::", this.state);*/

        let data = this.state;
        api.post("/ActualizarObservacion", data).then(res => {

            this.props.onClose();

        });

    }

    handleChange(name, value) {
        //console.log("ModalValoracion::name::", name);
        //console.log("ModalValoracion::value::", value);

        if (name == "Desestimado") {
            this.setState({
                [name]: value? -1 :0
            });
        }
        else {

            this.setState({
                [name]: value
            });
        }

    }

    render() {
        if (this.state.isLoading) {
            return (
                <Modal
                    size="lg"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Numero Oferta:&nbsp;{this.props.oferta.NumeroOferta}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Loading....</div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>*/}
                    </Modal.Footer>
                </Modal>
            );
        }
        else {
            return (
                <>
                    {/* Oferta */}
                    <Modal
                        size="lg"
                        show={this.props.show}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false} >
                        <Modal.Header className="py-1" closeButton>
                            <Modal.Title className="letra20">
                                Numero Oferta:&nbsp;{this.props.oferta.NumeroOferta}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="mt-1">

                                <div className="row ml-0">
                                    <div className="col col-lg-6">
                                        <CampoFicha label="Cliente" value={this.state.RazonSocial} />
                                    </div>
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Fecha de la Oferta" value={this.state.FechaOfertaStr} />
                                    </div>
                                    <div className="col col-lg-3">
                                        <CampoFicha label="Importe de la Oferta" value={this.state.BaseImponibleStr} />
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="col col-lg-8">
                                        <CampoFicha label="Consultor" value={this.state.Empleado} />
                                    </div>
                                    <div className="col col-lg-3">
                                        <CampoFicha label="Alcance" value={this.state.ObservacionesOferta} />
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="font-weight-bold mt-2 col-12">Descripción de la Oferta</div>
                                    <div className="col mt-1">
                                        <div className="row">
                                            <div className="col col-lg-11">
                                                <ServiceInput id="DescripcionOferta" rows="4" className="form-control form-control-sm" name="DescripcionOferta" type="textarea" default={this.state.DescripcionOferta} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="font-weight-bold mt-2 col-12">Observaciones</div>
                                    <div className="col mt-1">
                                        <div className="row">
                                            <div className="col col-lg-11">
                                                <ServiceInput id="ObservacionesContacto" rows="4" onChange={this.handleChange} className="form-control form-control-sm" name="ObservacionesContacto" type="textarea" default={this.state.ObservacionesContacto} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2 mt-1">
                                    <ServiceBool onChange={this.handleChange} id="Desestimado"
                                        name="Desestimado" title="Desestimado" size="sm" default={false} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-sm btn-primary" onClick={this.handleSave}>Guardar</button>
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                        </Modal.Footer>
                    </Modal>


                </>
            );
        }
    }
}

export default ModalOferta;
