import React, { Component } from 'react';

import * as api from 'components/Util/Api';
import Login from 'pages/Login/Login';
import Main from 'components/Main/Main';
import './App.css';

export const AppContext = React.createContext('');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sesion: null,
      isLoading: false,
      id: sessionStorage.getItem('id'),
      token: sessionStorage.getItem('token')
    };
  }

  ///////////////////
  componentDidMount() {
    //console.log("componentDidMount()");
    this.setState({ isLoading: true });
    if (this.state.token) {
      let par = {
        id: this.state.id,
        token: this.state.token
      };
      api.post('/EstadoSesion', par).then((res) => {
        //console.log("res::", res);
        if (res.data) {
          this.setState({
            sesion: res.data,
            isLoading: false
          });
        }
        else {
          //console.log("res.data::false");
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('token');
          this.setState({
            sesion: null,
            isLoading: false
          })
        }
      });
    }
  }

  render() {
    if (this.isLoading) {
      return <div>Loading....</div>
    }
    else if (!this.state.token || !this.state.sesion) {
      return <Login />
    }
    else {
      return (
        <>
          <AppContext.Provider value={this.state.sesion}>
            <Main />
          </AppContext.Provider>
        </>
      );
    }

  }
}

export default App;
