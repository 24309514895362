import React, { Component } from 'react';

const inputHidden = {
  position: 'absolute',
  height: '1px',
  width: '1px',
  overflow: 'hidden',
  clip: "rect('1px', '1px', '1px', '1px')",
}

class ServiceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: this.props.default ? this.props.default : false,
      file: null
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    //console.log("value::", value);
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    }, () => {
        //console.log("file::", this.state.file);
        this.props.onChange(this.props.name, this.state.file);
    });
  }
  
  ///////////////////
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }
    else {
      return (
        <div className="form-group">
          <React.Fragment>
            <input
              ref="fileInput"
              name={this.props.name}
              onChange={this.onChange}
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
            />
            <button onClick={() => this.refs.fileInput.click()}>{this.props.title}</button>
          </React.Fragment>
          <div className="card card-body w-100">
            <img src={this.state.file} />
          </div>
        </div>
      );
    }
  }
}

export default ServiceInput;


//<input
//  ref="file"
//  type="file"
//  accept="image/png, image/jpeg"
//  style={inputHidden}
//  name={this.props.name}
//  onChange={this.onChange} />



