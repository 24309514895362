import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import ConfirmDialog from 'components/Util/ConfirmDialog';

import Dropdown from 'react-bootstrap/Dropdown';

import ModalImputacion from './ModalImputacion';

// fontawesome
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

class ListaImputaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            parte: this.props.parte,
            imputaciones: this.props.parte.lImputacion
        }

        // Modal
        this.handleCloseEdit = this.handleCloseEdit.bind(this);
        this.hEditar = this.hEditar.bind(this);
        this.hEliminar = this.hEliminar.bind(this);

        // Nueva
        this.handleChange = this.handleChange.bind(this);
        // 
        this.leerImputaciones = this.leerImputaciones.bind(this);
        this.eliminarImputacion = this.eliminarImputacion.bind(this);
        this.cancelEliminarImputacion = this.cancelEliminarImputacion.bind(this);
    }

    componentDidMount() {
        // Leer Imputaciones
        //this.leerImputaciones();
    }

    leerImputaciones() {
        //console.log("leerImputaciones::");
        this.setState({ isLoading: true });
        let data = {
            CodigoEmpresa: this.props.parte.CodigoEmpresa,
            NumeroParteLc: this.props.parte.NumeroParteLc,
            SerieParteLc: this.props.parte.SerieParteLc,
            EjercicioParteLc: this.props.parte.EjercicioParteLc,
            CodigoTipoParteLc: this.props.parte.CodigoTipoParteLc
        }
        api.post("/ListaImputaciones", data).then(res => {
            //console.log("ListaImputaciones::res.data::", res.data);

            if (res.data) {
                this.setState({
                    imputaciones: res.data,
                    isLoading: false
                });
            }

            //this.setState = {
            //    isLoading: false,
            //    parte: res.data.parte,
            //    imputaciones: res.data.lImputacion
            //}
        });
    }

    eliminarImputacion(item) {
        //console.log("EliminarImputacion::item::", item);
        this.setState({
            showConfirmDelete: false
        });
        if (item) {
            this.setState({ isLoading: true });
            let data = {
                CodigoEmpresa: item.CodigoEmpresa,
                NumeroParteLc: item.NumeroParteLc,
                SerieParteLc: item.SerieParteLc,
                EjercicioParteLc: item.EjercicioParteLc,
                LineasPosicion: item.LineasPosicion
            }
            api.post("/BorrarImputacion", data).then(res => {
                //console.log("res.data::", res.data);
                this.leerImputaciones();
            });
        }
    }
    cancelEliminarImputacion() {
        this.setState({
            showConfirmDelete: false
        });
    }

    /////////////////////
    // Nueva imputaci�n
    handleChange(field, evt) {
        //console.log("ModalImputacion::field::", field);
        //console.log("ModalImputacion::evt::", evt);
        const name = evt.name;
        this.setState({
            [name]: field.value
        });
    }

    /////////////////////
    hEditar = item => () => {
        //console.log("ListaImputaciones::hEditar::item::", item);
        this.setState({
            showModalEdit: true,
            imputacion: item ? item : null,
            parte: this.props.parte ? this.props.parte : null
        });
    }
    hEliminar = item => () => {
        //console.log("hEliminar::", item);
        this.setState({
            showConfirmDelete: true,
            imputacion: item ? item : null
        });
    }

    handleCloseEdit() {
        this.leerImputaciones();
        this.setState({
            showModalEdit: false,
            imputacion: null
        });
    }

    render() {

        if (this.state.isLoading) {
            return <div>Loading....</div>
        }
        else {
            return (
                <>

                    {/* Lista de imputaciones */}
                    <div className="border p-1 mt-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Lista de Imputaciones</div>
                            <div className="btn-toolbar">
                                <button type="button" className="btn btn-sm btn-outline-primary ml-1" onClick={this.hEditar()}><FontAwesomeIcon icon={faPlus} />&nbsp;Nueva Imputaci&oacute;n</button>
                            </div>
                        </div>
                    </div>
                    <table className='table table-sm table-hover'>
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ width: '80px' }}>Fecha</th>
                                <th style={{ width: '80px' }}>Fase</th>
                                <th style={{ width: '150px' }}>Actividad</th>
                                {/*<th style={{ width: '70px' }}>Facturable</th>*/}
                                <th style={{ width: '200px' }}>Articulo</th>
                                <th style={{ width: '180px' }}>T&eacute;cnico</th>
                                <th style={{ width: '80px' }}>Horas</th>
                                <th style={{ width: '300px' }}>Observaciones</th>
                                <th style={{ width: '300px' }}>Observaciones Internas</th>
                                <th style={{ width: '100px' }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.imputaciones.length ? null :
                                this.state.imputaciones.map(c =>
                                    <tr key={c.LineasPosicion}>
                                        <td>{c.FechaImputacionLcStr}</td>
                                        <td>{c.TipoFaseLc}</td>
                                        <td>{c.ActividadParteLc}</td>
                                        {/*<td style={{ textAlign: 'center' }}>{c.EsImputable}</td>*/}
                                        <td>{c.DescripcionArticulo}</td>
                                        <td>{c.NombreTecnicoAsignadoLc}</td>
                                        <td>{c.Unidades}</td>
                                        <td>{c.Observaciones}</td>
                                        <td>{c.ObservacionesInternas}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" variant="light">
                                                    <FontAwesomeIcon icon={faCog} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item disabled={!c.Modificable} onClick={this.hEditar(c)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faEdit} /></span>&nbsp;Editar imputaci&oacute;n</Dropdown.Item>
                                                    <Dropdown.Item disabled={!c.Modificable} onClick={this.hEliminar(c)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faTrash} /></span>&nbsp;Eliminar imputaci&oacute;n</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={this.hEditar()}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faPlus} /></span>&nbsp;Nueva imputaci&oacute;n</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            {/*  <div className="btn-toolbar">*/}
                                            {/*    <button type="button" className="btn btn-xs btn-outline-info ml-1" disabled={!c.Modificable} onClick={this.hEditar(c)}><FontAwesomeIcon icon={faEdit} /></button>&nbsp;*/}
                                            {/*    <button type="button" className="btn btn-xs btn-outline-danger ml-1" disabled={!c.Modificable} onClick={this.hEliminar(c)}><FontAwesomeIcon icon={faTrash} /></button>*/}
                                            {/*  </div>*/}
                                        </td>
                                    </tr>

                                )}
                            {!this.state.imputaciones.length &&
                                <tr><td colSpan="9">No hay imputaciones</td></tr>
                            }
                        </tbody>
                    </table>

                    {/* Modal Imputaci�n */}
                    {(this.state.showModalEdit) ?
                        <ModalImputacion
                            show={this.state.showModalEdit}
                            imputacion={this.state.imputacion}
                            parte={this.state.parte}
                            onClose={this.handleCloseEdit}
                            mode={this.state.modo}
                        /> : null}

                    {/* Confirm */}
                    {(this.state.showConfirmDelete) ?
                        <ConfirmDialog
                            show={this.state.showConfirmDelete}
                            title="Eliminar imputaci&oacute;n"
                            message="Desea eliminar la imputaci&oacute;n?"
                            item={this.state.imputacion}
                            onAcept={this.eliminarImputacion}
                            onCancel={this.cancelEliminarImputacion}
                        />
                        :
                        null}


                </>
            );
        }
    }
}

export default ListaImputaciones;

