import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import ServiceSelect from 'components/Util/ServiceSelect';
import CampoFicha from 'components/Util/CampoFicha';
import ModalOferta from './ModalOferta';

/*import ServiceDate from 'components/Util/ServiceDate';*/
/*import ServiceSelect from 'components/Util/ServiceSelect';*/
import Dropdown from 'react-bootstrap/Dropdown';
import { AppContext } from 'App'
// fontawesome
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Ofertas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            CodigoCliente: '',
            tecnicoAsignado: '',
            ofertas: [],
            year: new Date().getFullYear(),
            Usuario: props.sesion,
            fechaDesde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: new Date(),
            showDetalleOferta: false,
            totalItemsCount: 0
        }
        Ofertas.contextType = AppContext;
        //

        this.hSelOfertas = this.hSelOfertas.bind(this);
        this.hShowDetalleOferta = this.hShowDetalleOferta.bind(this);
        this.hCloseDetalleOferta = this.hCloseDetalleOferta.bind(this);
        this.hChangeOferta = this.hChangeOferta.bind(this);
        

    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
        /*this.state.year = new Date().getFullYear();*/
        this.state.year = 0;
        this.hSelOfertas();
/*        console.log(this.state.year);*/
    }

    //componentWillUnmount() {
    //    // fix Warning: Can't perform a React state update on an unmounted component
    //    this.setState = (state, callback) => {
    //        return;
    //    };
    //}

    ////////////////////


    hSelOfertas() {

        let data = {
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : this.context.Empleado.IdEmpleado,
            EjercicioOferta: this.state.year,
            NoAsignado: this.state.NoAsignado
        }

        api.post("/Ofertas", data).then(res => {
            this.setState({ ofertas: res.data, isLoading: false, totalItemsCount: res.data.length});
        });
    }

    hCloseDetalleOferta() {
/*        console.log("hCloseDetalleOferta");*/
        this.setState({
            showDetalleOferta: false
        });

        this.hSelOfertas(this.state.year);
    }

    hShowDetalleOferta(item) {
        this.setState({
            showDetalleOferta: true,
            itemSelected: item
        });
    }

    hChangeOferta(name, value) {
        //console.log("hChangeFacturacion::", name + "" + value);
        this.setState({
            [name]: value,
            showDetalleOferta: false
        });
    }


    render() {
        return (
            <>
                {/* Lista de ofertas */}
                <div>
                    <div className="border p-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Ofertas Pendientes</div>
                        </div>
                    </div>
                </div>

                {/* Buscar */}
                <div className="border pt-2 pl-2 pb-3">
                    <div className="d-flex flex-row justify-content-start">
                        {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChangeOferta} name="NoAsignado" service="ListaAsignadoSelect" title="Asignado" first={{ value: '3', label: '--Todos--' }} default={{ value: '3', label: '--Todos--' }} />
                            </div>
                        ) : ""}
                        {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChangeOferta} name="tecnicoAsignado" service="ListaTecnicosNoExtSelect" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={this.state.TecnicoAsignadoLc ? { value: this.state.TecnicoAsignadoLc, label: this.state.NombreTecnicoAsignadoLc } : { value: this.context.Empleado.IdEmpleado, label: this.context.Empleado.Nombre }} />
                            </div>
                        ) : ""}
                        <div className="w-20 px-1">
                            <ServiceSelect onChange={this.hChangeOferta} name="year" service="ListaYear" title="A&ntilde;o" first={{ value: '0', label: '--Todos--' }} default={{ value: '0', label: '--Todos--' }} />
                        </div>

                        <div className="w-10 px-1">
                            <div className="d-flex flex-column">
                                <div>&nbsp;</div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.hSelOfertas()}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border">
                    <div className="col ml-0 pl-1 mt-3">
                        <div className="alert alert-secondary p-1 m-0" style={{ fontWeight: 'bold', width: '150px' }}>Registros Totales: {this.state.totalItemsCount}</div>
                    </div>
                    
                    <table className='table table-sm table-bordered mt-3 col-12'>
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ width: "60px" }}>N.Oferta</th>
                                <th style={{ width: "200px" }}>Raz&oacute;n Social</th>
                                <th style={{ width: "70px" }}>C.Cliente</th>
                                {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                                    <th style={{ width: "80px" }}>Consultor</th>
                                ) : ""}
                                <th style={{ width: "70px" }}>F.Oferta</th>
                                <th style={{ width: "80px" }}>B.Imponible</th>
                                <th style={{ width: "80px" }}>Alcance </th>
                                <th style={{ width: "300px" }}>Descripci&oacute;n de la Oferta</th>
                                <th style={{ width: "300px" }}>Observaciones</th>
                                <th style={{ width: "10px" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.ofertas.length ? null :
                                this.state.ofertas.map(item =>
                                    <tr className="cursorpointer" key={item.Id}>
                                        <td>{item.NumeroOferta}</td>
                                        <td className="ellipsis">{item.RazonSocial}</td>
                                        <td>{item.CodigoCliente}</td>
                                        {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                                            <td className="ellipsis">{item.Empleado}</td>
                                        ) : ""}
                                        <td>{item.FechaOfertaStr}</td>
                                        <td>{item.BaseImponibleStr}</td>
                                        <td>{item.ObservacionesOferta}</td>
                                        <td>{item.DescripcionOferta}</td>
                                        <td>{item.ObservacionesContacto}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" variant="light">
                                                    <FontAwesomeIcon icon={faCog} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.hShowDetalleOferta(item)}><span className="btn btn-xs btn-outline-secondary"><FontAwesomeIcon icon={faEdit} /></span>&nbsp;Editar oferta</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )}
                            {this.state.ofertas.length == 0 &&
                                <tr><td colSpan="7">No hay datos para la consulta</td></tr>
                            }
                        </tbody>
                    </table>
                </div>

                {/* Modal detalle valoracion */}
                {(this.state.showDetalleOferta) ?
                    <ModalOferta
                        show={this.state.showDetalleOferta}
                        oferta={this.state.itemSelected}
                        onClose={this.hCloseDetalleOferta}
                    /> : null}
            </>
        );
    }


}

export default Ofertas;


