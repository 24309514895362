import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import { AppContext } from 'App'

import Dropdown from 'react-bootstrap/Dropdown';

import ModalDetalleValoracion from './ModalDetalleValoracion';

import ServiceSelect from 'components/Util/ServiceSelect';
import ServiceDate from 'components/Util/ServiceDate';
//import ServiceInput from 'components/Util/ServiceInput';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

// pagination
import Pagination from "react-js-pagination";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCog, faEdit, faFilePdf, faHourglassEnd,faCircle,faCertificate } from '@fortawesome/free-solid-svg-icons';

class Valoraciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            valoraciones: {},
            CodigoCliente: '',
            tecnicoAsignado: '',
            Usuario: props.sesion,
            showDetalleValoracion: false,
            Id: '',
            Estado: '',
            itemSelected: {},
            listavaloraciones: [],
            listaview: [],
            fechaDesde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: new Date(),
            // pagination
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
        }
        Valoraciones.contextType = AppContext;
        //

        this.hSelValoracion = this.hSelValoracion.bind(this);
        this.hSeleccionarValoracion = this.hSeleccionarValoracion.bind(this);

        // Modal Detalle Valoracion
        this.hShowDetalleValoracion = this.hShowDetalleValoracion.bind(this);
        this.hChangeDetalleValoracion = this.hChangeDetalleValoracion.bind(this);
        this.hCloseDetalleValoracion = this.hCloseDetalleValoracion.bind(this);

        this.formatDate = this.formatDate.bind(this);

        // 

    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
        let fechaDesde = new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1);
        this.hSelValoracion(fechaDesde);
        //console.log(this.context.Empleado);
    }

    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    hChangeDetalleValoracion(name, value) {
        //console.log("hChangeDetalleValoracion::", name + "" + value);
        this.setState({
            [name]: value,
            showDetalleValoracion: false
        });
        /*        this.hSelValoracion();*/
    }
    hCloseDetalleValoracion() {

        this.setState({
            showDetalleValoracion: false
        });

        let fechaDesde = new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1);
        this.hSelValoracion(fechaDesde);

    }

    hSelValoracion(fechaDesde) {

        let data = {
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : '',
            Id: this.state.Id ? this.state.Id : '',
            Estado: this.state.Estado ? this.state.Estado : '',
            FechaDesde: fechaDesde != undefined ? this.formatDate(fechaDesde) : this.formatDate(this.state.fechaDesde),
            FechaHasta: this.formatDate(this.state.fechaHasta)
        }

        api.post("/ListaValoraciones", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({ listavaloraciones: res.data, showDetalleValoracion: false, isLoading: false });
            /* this.props.onSelect(null);*/

            // Paginaci�n
            this.setState({
                totalItemsCount: res.data.length,
            })
            this.setPagination(1);
        });
    }


    setPagination(item) {
        //console.log("setPagination::n::", item);
        var list = [];
        var ini = (item - 1) * this.state.itemsCountPerPage;
        var fin = (item) * this.state.itemsCountPerPage;
        for (var i = ini; i < this.state.listavaloraciones.length && i < fin; i++) {
            list.push(this.state.listavaloraciones[i]);
        }
        this.setState({ activePage: item, listaview: list });
    }

    // Modal Detalle Valoracion
    hShowDetalleValoracion(item) {
        //console.log("ListaValoracion::hShowDetalleValoracion::", item);
        this.setState({
            showDetalleValoracion: true,
            itemSelected: item
        });
    }

    // Seleccionar Valoracion
    hSeleccionarValoracion(item) {
        //console.log("hSeleccionarValoracion");
        this.setState({
            valoracionSeleccionada: item.Id
        });
    }

    render() {
        return (
            <>
                {/* Lista de valoraciones */}
                <div>
                    <div className="border p-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Lista de Valoraciones</div>
                        </div>
                    </div>
                    {/* Buscar */}
                    <div className="border p-1">
                        <div className="d-flex flex-row justify-content-start">

                            {(this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR') ? (
                                <div className="w-20 px-1">
                                    <ServiceSelect onChange={this.hChangeDetalleValoracion} name="tecnicoAsignado" service="ListaTecnicosNoExtSelect" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={{ value: ' ', label: '--Todos--' }} />
                                </div>) : ""
                            }

                            {(this.context.Empleado.CodigoEquipoAplicadoresLc == 'TC200')  ? (
                                <div className="w-20 px-1">
                                    <ServiceSelect onChange={this.hChangeDetalleValoracion} name="tecnicoAsignado" service="ListaEquipoAplicadoresTC200Select" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={{ value: ' ', label: '--Todos--' }} />
                                </div>) : ""
                            }
                            {(this.context.Empleado.CodigoEquipoAplicadoresLc == 'TC50') ? (
                                <div className="w-20 px-1">
                                    <ServiceSelect onChange={this.hChangeDetalleValoracion} name="tecnicoAsignado" service="ListaEquipoAplicadoresTC50Select" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={{ value: ' ', label: '--Todos--' }} />
                                </div>) : ""
                            }
                            {(this.context.Empleado.CodigoEquipoAplicadoresLc == 'EQ2') ? (
                                <div className="w-20 px-1">
                                    <ServiceSelect onChange={this.hChangeDetalleValoracion} name="tecnicoAsignado" service="ListaEquipoAplicadoresEQ2Select" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={{ value: ' ', label: '--Todos--' }} />
                                </div>) : ""
                            }
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChangeDetalleValoracion} name="Estado" service="EstadosValoracionesSelect" title="Estado" first={{ value: ' ', label: '--Todos--' }} default={{ value: ' ', label: '--Todos--' }} />
                            </div>

                            <div className="w-10 px-1">
                                <ServiceDate onChange={this.hChangeDetalleValoracion} name="fechaDesde" title="Fecha desde" default={this.state.fechaDesde} />
                            </div>
                            <div className="w-10 px-1">
                                <ServiceDate onChange={this.hChangeDetalleValoracion} name="fechaHasta" title="Fecha hasta" default={this.state.fechaHasta} />
                            </div>
                            <div className="w-10 px-1">
                                <div className="d-flex flex-column">
                                    <div>&nbsp;</div>
                                    <div>
                                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.hSelValoracion()}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <table className='table table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead className="thead-dark">
                                <tr>
                                    <th></th>
                                    <th>Numero OP</th>
                                    <th>T&eacute;cnico</th>
                                    <th>Fecha</th>
                                    <th>Limite de Contacto</th>
                                    <th>Estado</th>
                                    <th>Fecha contacto</th>
                                    <th>Razon Social</th>
                                    <th>Nombre</th>
                                    <th>Tel&eacute;fono</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isLoading ?
                                    <tr><td colSpan="11">Loading...</td></tr>
                                    : null
                                }
                                {!this.state.isLoading && !this.state.listavaloraciones.length ?
                                    <tr><td colSpan="11">No hay datos para la consulta</td></tr>
                                    : null
                                }
                                {!this.state.isLoading && this.state.listavaloraciones.length ?
                                    this.state.listaview.map(item =>
                                        <tr className={"cursorpointer " + (this.state.valoracionSeleccionada === item.Id ? 'bg-grey' : '') + ' ' + (item.TLimiteContacto == 'S' ? 'alert-danger' : item.TLimiteContacto == 'H' ? 'alert-warning' : item.TLimiteContacto == 'F' ? 'alert-primary' : '')}
                                            onDoubleClick={() => this.hShowDetalleValoracion(item)}
                                            key={item.Id}>
                                            
                                            {item.Leido == 0 ? (
                                                <td><FontAwesomeIcon icon={faCertificate} size="2x" /></td>
                                            ) : <td></td>}
                                            <td>{item.NumeroOP}</td>
                                            <td>{item.NombreConsultor}</td>
                                            <td>{item.FechaFmt}</td>
                                            <td>{item.LimiteContacto}</td>
                                            <td>{item.ED_StatusReferenciaFmt}</td>
                                            <td>{item.FechaConctactoFmt}</td>
                                            <td>{item.RazonSocial}</td>
                                            <td>{item.Nombre}</td>
                                            <td>{item.Telefono}</td>
                                            <td>
                                                <button type="button" data-toggle="tooltip" data-placement="left" title="Ver detalle" onClick={() => this.hShowDetalleValoracion(item)} className="btn btn-sm btn-outline-info"><FontAwesomeIcon icon={faEdit} /></button>
                                            </td>
                                        </tr>
                                    )
                                    : null
                                }
                            </tbody>
                        </table>
                        <div className="d-flex flex-row justify-content-center">
                            <div>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    onChange={this.setPagination.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    prevPageText="<"
                                    nextPageText=">"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal detalle valoracion */}
                {(this.state.showDetalleValoracion) ?
                    <ModalDetalleValoracion
                        show={this.state.showDetalleValoracion}
                        valoracion={this.state.itemSelected}
                        onClose={this.hCloseDetalleValoracion}
                        onChange={this.hChangeDetalleValoracion}
                    /> : null}
            </>
        );
    }


}

export default Valoraciones;
