import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    flexDirection: 'row',
    marginTop: '15px',
    //alignItems: 'stretch',
  },
  leftColumn: {
    flexDirection: 'column',
    width: "55%",
    alignSelf: 'flex-center',
    justifySelf: 'flex-center',
  },
  centerColumn: {
    flexDirection: 'column',
    width: "5%",
    alignSelf: 'flex-center',
    justifySelf: 'flex-center',
  },
  rigthColumn: {
    flexDirection: 'column',
    width: "40%",
    alignSelf: 'flex-center',
    justifySelf: 'flex-center',
  },
  title: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    textDecoration: 'none',
    alignContent: 'center',
    alignSelf: 'center',
    lineHeight: 1.5,
    paddingTop: '5px',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableCol: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHead: {
    display: "table",
    borderStyle: "solid",
    backgroundColor: "#e0e0e0",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  tableCellLeft: {
    alignContent: 'left',
    alignSelf: 'left',
    lineHeight: 1.3,
  },
});

export default (props) => (
  <>
    <View style={styles.container}>

      {/*Left*/}
      <View style={styles.leftColumn}>
        <Text style={styles.title}>PARTE DE ASISTENCIA</Text>
      </View>

      {/*Center*/}
      <View style={styles.centerColumn}>
      </View>

      {/*Right*/}
      <View style={styles.rigthColumn}>
        <TablaParte parte={props.parte} />
      </View>

    </View>

    <View style={styles.container}>

      {/*Left*/}
      <View style={styles.leftColumn}>
        <TablaDelegacion parte={props.parte} />  
        <View style={{ height: "10px"}}></View>
        <TablaProyecto parte={props.parte} />
      </View>

      {/*Center*/}
      <View style={styles.centerColumn}>
      </View>

      {/*Right*/}
      <View style={styles.rigthColumn}>
        <TablaCliente parte={props.parte} />
      </View>
    </View>

  </>
);

{/* Tabla Parte */}
function TablaParte(props) {
  return (
    <>
      <View style={styles.table}>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>Tipo</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>Parte</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>Fecha parte</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.CodigoTipoParteLc}</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.NumeroParteLc}</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '33.3%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.Fecha}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
} 

{/* Tabla Delegacion */ }
function TablaDelegacion(props) {
  return (
    <>
      <View style={styles.table}>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '25%' }]}>
            <View style={styles.tableCell}>
              <Text>Delegación</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '25%' }]}>
            <View style={styles.tableCell}>
              <Text>Inicio Asistencia</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '17%' }]}>
            <View style={styles.tableCell}>
              <Text>Hora Inicio</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '17%' }]}>
            <View style={styles.tableCell}>
              <Text>Fecha Fin</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '16%' }]}>
            <View style={styles.tableCell}>
              <Text>Hora Fin</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '25%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.Delegacion}</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '25%' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '17%' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '17%' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '16%' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '50%' }]}>
            <View style={styles.tableCell}>
              <Text>Persona Contacto</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '50%' }]}>
            <View style={styles.tableCell}>
              <Text>Número Pedido</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '50%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.PersonaContactoLc}</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '50%' }]}>
            <View style={styles.tableCell}>
              <Text></Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
} 

{/* Tabla Proyecto */ }
function TablaProyecto(props) {
  return (
    <>
      <View style={styles.table}>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '45%' }]}>
            <View style={styles.tableCell}>
              <Text>Proyecto</Text>
            </View>
          </View>
          <View style={[styles.tableColHead, { width: '55%' }]}>
            <View style={styles.tableCell}>
              <Text>Técnico</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '45%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.CodigoProyecto}</Text>
            </View>
          </View>
          <View style={[styles.tableCol, { width: '55%' }]}>
            <View style={styles.tableCell}>
              <Text>{props.parte.NombreEmpleado}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
} 

{/* Tabla Cliente */ }
function TablaCliente(props) {
  return (
    <>
      <View style={styles.table}>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableColHead, { width: '100%' }]}>
            <View style={styles.tableCell}>
              <Text>Nombre y domicilio del cliente</Text>
            </View>
          </View>
        </View>
        {/**/}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '100%', borderBottomWidth: 0 }]}>
            <View style={[styles.tableCellLeft, { minHeight: "82px", borderBottomWidth: 0 }]}>
              <Text style={{ fontWeight: 'bold' }}>{props.parte.Cliente.CodigoCliente}      {props.parte.Cliente.CifEuropeo}</Text>
              <Text>{props.parte.Cliente.RazonSocial}</Text>
              <Text>{props.parte.Cliente.Domicilio}</Text>
              <Text>{props.parte.Cliente.CodigoPostal}-{props.parte.Cliente.Municipio}</Text>
              <Text>{props.parte.Cliente.Provincia}</Text>
              <Text>Teléfono: {props.parte.Cliente.Telefono}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
} 
