import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import ServiceDate from 'components/Util/ServiceDate';
/*import ServiceSelect from 'components/Util/ServiceSelect';*/

import { AppContext } from 'App'

import { faSearch } from '@fortawesome/free-solid-svg-icons';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Productividad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            CodigoCliente: '',
            tecnicoAsignado: '',
            productividad: [],
            year: new Date().getFullYear(),
            Usuario: props.sesion,
            fechaDesde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: new Date()
        }
        Productividad.contextType = AppContext;
        //

        this.formatDate = this.formatDate.bind(this);
        this.hSelProductividad = this.hSelProductividad.bind(this);
        this.hChangeProductividad = this.hChangeProductividad.bind(this);
        this.hUpdateProductividad = this.hUpdateProductividad.bind(this);

    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
        this.state.year = new Date().getFullYear();
        this.hSelProductividad(this.state.year);
/*        console.log(this.state.year);*/
    }

    ////////////////////

    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    hSelProductividad(year) {


        let data = {
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : this.context.Empleado.IdEmpleado,
            year: year != undefined ? year : this.state.year != undefined ? this.state.year : new Date().getFullYear()
        }

        api.post("/Productividad", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({ productividad: res.data, isLoading: false });
           /* console.log(this.state.productividad);*/
        });
    }

    hChangeProductividad(fechaDesde) {

        let data = {
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : this.context.Empleado.IdEmpleado,
            FechaDesde: fechaDesde != undefined ? this.formatDate(fechaDesde) : this.formatDate(this.state.fechaDesde),
            FechaHasta: this.formatDate(this.state.fechaHasta)
        }

        api.post("/Productividad", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({ productividad: res.data, isLoading: false });
        });
    }

    hUpdateProductividad(name, value) {
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <>
                {/* Lista de productividad */}
                <div>
                    <div className="border p-1 bg-light">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="h5">Productividad</div>
                        </div>
                    </div>
                </div>
                <div className="border p-1">
                    <div className="d-flex flex-row justify-content-start">

                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hUpdateProductividad} name="fechaDesde" title="Fecha desde" default={this.state.fechaDesde} />
                        </div>
                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hUpdateProductividad} name="fechaHasta" title="Fecha hasta" default={this.state.fechaHasta} />
                        </div>
                        <div className="w-10 px-1">
                            <div className="d-flex flex-column">
                                <div>&nbsp;</div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.hChangeProductividad()}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border p-2 pl-2 scrolldiv w-100 ">
                    <table className='table table-bordered table-sm overflow-auto'>
                        <thead className="thead-dark">
                            <tr>
                                <th>FechaParte</th>
                                <th>Tipo</th>
                                <th>Ejercicio</th>
                                <th>Serie</th>
                                <th>NParte</th>
                                <th>C&oacute;dCliente</th>
                                <th>RazonSocial</th>
                                <th>RazonSocialDest</th>
                                <th>C&oacute;dProy</th>
                                <th>C&oacute;Fase</th>
                                <th>Actividad</th>
                                <th>H.Imp.</th>
                                <th>H.Fase.</th>
                                <th>H.Previas.</th>
                                <th>H.Disp.</th>
                                <th>H.Fact.</th>
                                <th>H.Exc.</th>
                                <th>H.Ben</th>
                                <th>%Fac.</th>
                                <th>Observaciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.productividad.length ? null :
                                this.state.productividad.map(item =>
                                    <tr className={"cursorpointer " + (item.Orden == 2 ? 'bg-dia' : item.Orden == 3 ? 'bg-semana' : item.Orden == 4 ? 'bg-mes' : item.Orden == 5 ? 'bg-annyo' : 'bg-gris')} key={item.LineasPosicion}>
                                        <td>{item.FechaParteStr}</td>
                                        <td>{item.CodigoTipoParteLc}</td>
                                        <td>{(item.EjercicioParteLc == 0 ? "" : item.EjercicioParteLc)}</td>
                                        <td>{item.SerieParteLc}</td>
                                        <td>{(item.NumeroParteLc == 0 ? "" : item.NumeroParteLc)}</td>
                                        <td>{item.CodigoCliente}</td>
                                        <td>{item.RazonSocial}</td>
                                        <td>{item.RazonSocialDest}</td>
                                        <td>{item.CodigoProyecto}</td>
                                        <td>{item.CodigoTipoFaseLc}</td>
                                        <td>{(item.CodigoActividadParteLc == 0 ? "" : item.CodigoActividadParteLc)}</td>
                                        <td>{item.HorasImputadasFmt}</td>
                                        <td>{item.HorasFaseFmt}</td>
                                        <td>{item.HorasPreviasFmt}</td>
                                        <td>{item.HorasDisponiblesFmt}</td>
                                        <td>{item.HorasFacturablesFmt}</td>
                                        <td>{item.HorasExcesoFmt}</td>
                                        <td>{item.HorasBeneficioFmt}</td>
                                        <td>{item.PorcentajeFmt} %</td>
                                        <td>{item.Observaciones}</td>
                                    </tr>
                                )}
                            {this.state.productividad.length == 0 &&
                                <tr><td colSpan="20">No hay datos para la consulta</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }


}

export default Productividad;


