
import React, { Component } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';

import { AppContext } from 'App'

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

//
import CabeceraLogo from './CabeceraLogo';
import CabeceraParte from './CabeceraParte';
import ArticuloParte from './ArticuloParte';
import PieParte from './PieParte';
import Cargos from './Cargos';
import ArticulosFirma from './ArticulosFirma';
import InspeccionFinal from './InspeccionFinal';

// Register font
//Font.register({ family: 'Roboto', src: source });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
});


class ParteAsistencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      firstday: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
      lastday: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      }

      this.handleDescargado = this.handleDescargado.bind(this);
      ParteAsistencia.contextType = AppContext;
  }


    handleDescargado() {
        const { handleParteDescargado } = this.props;
        handleParteDescargado();
    }

    render() {

      return (
       <PDFDownloadLink
        className="btn btn-primary btn-sm"
        document={<Pdf parte={this.props.parte} data={this.props.data} />}
        fileName={this.props.filename}>
              {({ blob, url, loading, error, create }) => (loading ? 'Loading document...' : <div onClick={this.handleDescargado}><FontAwesomeIcon icon={faFilePdf} />&nbsp;Parte Asistencia</div>)}
      </PDFDownloadLink>
    );
  }
}
export default ParteAsistencia;

{/* Pdf */}
function Pdf(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <CabeceraLogo />

        <CabeceraParte parte={props.parte} />

        <ArticuloParte parte={props.parte} data={props.data} />

        <Cargos parte={props.parte} data={props.data} />

        <ArticulosFirma parte={props.parte} data={props.data} />

        <InspeccionFinal parte={props.parte} data={props.data} />

        <PieParte parte={props.parte} />

      </Page>
    </Document>
  );
}




