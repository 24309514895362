import React, { Component } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'

import ServiceSelect from 'components/Util/ServiceSelect';
import ServiceDate from 'components/Util/ServiceDate';
import ServiceInput from 'components/Util/ServiceInput';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class InformeImputaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            informe: {},
            NumeroParteLc: 0,
            CodigoCliente: '',
            fechaDesde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            fechaHasta: new Date(),
            tecnicoAsignado: '',
            tipoActividad: '',
            Usuario: props.sesion
        }
        InformeImputaciones.contextType = AppContext;
        //
        this.hChange = this.hChange.bind(this);
        // 
        this.leerImputaciones = this.leerImputaciones.bind(this);

        this.formatDate = this.formatDate.bind(this);

    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
        this.leerImputaciones();

    }

    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    hChange(name, value) {
        //console.log("Partes::name::", name);
        //console.log("Partes::value::", value);
        this.setState({
            [name]: value
        }, () => {
            this.leerImputaciones();
        });
    }

    leerImputaciones() {
        //console.log("leerImputaciones::item::", item);

        this.setState({ isLoading: true });
        let data = {
            NumeroParteLc: this.state.NumeroParteLc ? this.state.NumeroParteLc : 0,
            CodigoCliente: this.state.CodigoCliente ? this.state.CodigoCliente : '',
            tecnicoAsignado: this.state.tecnicoAsignado ? this.state.tecnicoAsignado : '',
            tipoActividad: this.state.tipoActividad ? this.state.tipoActividad : '',
            FechaDesde: this.formatDate(this.state.fechaDesde),
            FechaHasta: this.formatDate(this.state.fechaHasta)
        }
        api.post("/InformeImputaciones", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({
                informe: res.data,
                isLoading: false
            });
        });
    }



    render() {

        return (
            <>
                {/* Lista de imputaciones */}
                <div className="border p-1 mt-1 bg-light">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="h5">Informe de Imputaciones</div>
                    </div>
                </div>
                {/* Buscar */}
                <div className="border p-1">
                    <div className="d-flex flex-row justify-content-start">
                        <div className="w-10 px-1">
                            <ServiceInput onChange={this.hChange} name="NumeroParteLc" title="N&uacute;mero de parte" />
                        </div>

                        <div className="w-20 px-1">
                            <ServiceSelect onChange={this.hChange} name="CodigoCliente" service="ListaClientesSelect" title="Cliente" default={{ value: '', label: '--Todos--' }} />
                        </div>

                        {this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR' ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChange} name="tecnicoAsignado" service="ListaTecnicosSelect" title="T&eacute;cnico" first={{ value: ' ', label: '--Todos--' }} default={{ value: '', label: '--Todos--' }} />
                            </div>) : ""
                        }

                        {this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR' ? (
                            <div className="w-20 px-1">
                                <ServiceSelect onChange={this.hChange} name="tipoActividad" service="ListaTipoActividadSelect" title="Actividad" first={{ value: ' ', label: '--Todos--' }} default={{ value: '', label: '--Todos--' }} />
                            </div>) : ""
                        }

                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hChange} name="fechaDesde" title="Fecha desde" default={this.state.fechaDesde} />
                        </div>
                        <div className="w-10 px-1">
                            <ServiceDate onChange={this.hChange} name="fechaHasta" title="Fecha hasta" default={this.state.fechaHasta} />
                        </div>
                        <div className="w-10 px-1">
                            <div className="d-flex flex-column">
                                <div>&nbsp;</div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.leerImputaciones()}><FontAwesomeIcon icon={faSearch} /></button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lista de imputaciones */}
                {this.state.informe.lInforme && !this.state.isLoading ?
                    <TablaListado informe={this.state.informe} />
                    : null
                }

            </>
        );

    }
}

export default InformeImputaciones;


////////////////////////////////////
// tabla listado
//
function TablaListado(props) {
    return (
        <>
            <table className='table table-sm'>
                <thead className="thead-dark">
                    <tr>
                        <th style={{ width: '120px' }}>Fecha</th>
                        <th>Parte</th>
                        <th>Cliente</th>
                        <th>Fase</th>
                        <th>Actividad</th>
                        <th>Articulo</th>
                        <th>T&eacute;cnico</th>
                        <th>Horas</th>
                        <th style={{ width: "300px" }}>Observaciones</th>
                        <th style={{ width: "200px" }}>Observaciones Internas</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                {!props.informe.lInforme.length ? null :
                    props.informe.lInforme.map((inf, index) =>
                        <tbody key={index}>
                            {!inf.lImputacion.length ? null :
                                inf.lImputacion.map(c =>
                                    <tr key={c.LineasPosicion}>
                                        <td>{c.FechaImputacionLcStr}</td>
                                        <td>{c.NumeroParteLc}</td>
                                        <td>{c.NombreCliente}</td>
                                        <td>{c.TipoFaseLc}</td>
                                        <td>{c.ActividadParteLc}</td>
                                        <td>{c.CodigoArticulo}-{c.DescripcionArticulo}</td>
                                        <td>{c.NombreTecnicoAsignadoLc}</td>
                                        <td>{c.Unidades}</td>
                                        <td>{c.Observaciones}</td>
                                        <td>{c.ObservacionesInternas}</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                )}
                            {/* Total Cliente/Parte */}
                            <tr style={{ backgroundColor: '#f8f9fa' }}>
                                <td colSpan="6">&nbsp;</td>
                                <td style={{ textAlign: 'right', fontSize: 14, fontWeight: 'bold' }} >Total Cliente/Parte</td>
                                <td style={{ fontSize: 14, fontWeight: 'bold' }} >{inf.TotalHoras}</td>
                                <td >&nbsp;</td>
                            </tr>
                        </tbody>
                    )}

                {/* Total Final */}
                <tbody>
                    <tr style={{ backgroundColor: '#cecece' }}>
                        <td colSpan="6">&nbsp;</td>
                        <td style={{ textAlign: 'right', fontSize: 14, fontWeight: 'bold' }} >Total Horas</td>
                        <td style={{ fontSize: 14, fontWeight: 'bold' }} >{props.informe.TotalHoras}</td>
                        <td >&nbsp;</td>
                    </tr>
                </tbody>

            </table>
        </>
    );
}