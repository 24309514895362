import React from 'react';

import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 8,
    flexDirection: 'row',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    margin: "auto",
  },
  tableCol: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHead: {
    display: "table",
    borderStyle: "solid",
    backgroundColor: "#e0e0e0",
    minHeight: "13px",
    padding: 1,
    fontWeight: 'extrabold',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  tableColBorder: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
  },
  tableColBorderTopBottom: {
    display: "table",
    borderStyle: "solid",
    minHeight: "13px",
    padding: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  text: {
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  textBold: {
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'bold',
  }
});

export default (props) => (
  <>
    <View style={styles.container}>

      <View style={styles.table}>
        {/**/}
        <CabeceraArticulos parte={props.parte} />
        {/**/}
        <LineaArticulos parte={props.parte} />
        <LineaArticulos parte={props.parte} />
        <LineaArticulos parte={props.parte} />
        <LineaArticulos parte={props.parte} />

        {/**/}
        <CabeceraFirma parte={props.parte} />
        {/**/}
        <LineaFirma parte={props.parte} data={props.data} />

      </View>

    </View>
  </>
);

function CabeceraArticulos(props) {
  return (
    <View style={styles.tableRow}>
      <View style={[styles.tableColHead, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text>Articulo</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '25%' }]}>
        <View style={styles.tableCell}>
          <Text>Descripción</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text>Precio</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text>Cantidad</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text>I.V.A.</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text>Importe total</Text>
        </View>
      </View>
    </View>
  );
}

function LineaArticulos(props) {
  return (
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '25%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '15%' }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
    </View>
  );
}

function CabeceraFirma(props) {
  return (
    <View style={styles.tableRow}>
      <View style={[styles.tableColHead, { width: '33.3%' }]}>
        <View style={styles.tableCell}>
          <Text>VºBº Cliente Firma y Sello</Text>
        </View>
      </View>
      <View style={[styles.tableColHead, { width: '33.3%' }]}>
        <View style={styles.tableCell}>
          <Text>Firma del Técnico</Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '33.3%', borderBottomWidth: 0 }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
    </View>
  );
}

function LineaFirma(props) {
  return (
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: '33.3%', minHeight: "60px" }]}>
        <View style={styles.tableCell}>
          <Text></Text>
        </View>
      </View>
      <View style={[styles.tableCol, { width: '33.3%', minHeight: "60px" }]}>
        <View style={styles.tableCell}>
          {props.data.Firma ?
            <Image
              style={{ width: 180, height: 60, padding: 5 }}
              source={props.data.Firma}
            />
            : null }
        </View>
      </View>
      <View style={[styles.tableCol, { width: '33.3%', minHeight: "60px" }]}>
        <View style={styles.tableCell}>
          <Text style={styles.textBold}>Responsable Parte:</Text>
          <Text>{props.parte.NombreResponsableParteLc}</Text>
        </View>
      </View>
    </View>
  );
}

