
import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import Modal from 'react-bootstrap/Modal';
import CampoFicha from 'components/Util/CampoFicha';
import ServiceDate from 'components/Util/ServiceDate';
import ServiceInput from 'components/Util/ServiceInput';


class ModalFinalizarParte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.parte,
            isLoading: true,
            date: new Date(),
            firstday: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
            lastday: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),

            FechaCierreLc: new Date()
        }

        this.handleChange = this.handleChange.bind(this);
        // Modal
        this.handleClose = this.handleClose.bind(this);
        this.handleAcept = this.handleAcept.bind(this);
        this.validate = this.validate.bind(this);
    }

    // 
    handleChange(name, value) {
        //console.log("ModalImputacion::name::", name);
        //console.log("ModalImputacion::value::", value);
        this.setState({
            [name]: value
        });
    }

    // Modal
    handleClose() {
        this.props.onClose();
        this.setState({
            showModal: false
        });
    }
    handleAcept() {

        if (this.validate()) {
            //console.log("ModalFinalizarParte::handleAcept::this.state::", this.state);
            this.setState({ isLoading: true });
            let data = this.state;
            api.post("/FinalizarParte", data).then(res => {
                //console.log("res.data::", res.data);

                this.props.onClose();
                this.setState({
                    isLoading: false
                });
            });
        }
    }
    validate() {
        var valid = false;
        if (!this.state.Observaciones) {
            this.setState({ msgError: 'Debe informar las observaciones' });
        }
        else {
            valid = true;
        }

        return valid;
    }

    render() {
        return (
            <>
                <Modal
                    size="xl"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Finalizar Parte:&nbsp;{this.props.parte.NumeroParteLc}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col col-lg-3">
                                <ServiceDate onChange={this.handleChange}
                                    name="FechaCierreLc"
                                    title="Fecha de finalizaci&oacute;n"
                                    default={this.state.date}
                                    mindate={this.state.firstday}
                                    maxdate={this.state.lastday}
                                    inline={true}
                                    disabled={this.props.mode == 'update' ? true : false}
                                />
                            </div>
                            <div className="col col-lg-9">
                                <div className="row">
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Tipo" value={this.props.parte.CodigoTipoParteLc} />
                                    </div>
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Fecha" value={this.props.parte.Fecha} />
                                    </div>
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Proyecto" value={this.props.parte.CodigoProyecto} />
                                    </div>
                                    <div className="col col-lg-6">
                                        <CampoFicha label="J.Proyecto" value={this.props.parte.ResponsableParteLc + '-' + this.props.parte.NombreResponsableParteLc} />
                                    </div>
                                    <div className="col-12">
                                        <CampoFicha label="Cliente" value={this.props.parte.CodigoCliente + '-' + this.props.parte.NombreCliente} />
                                    </div>
                                    <div className="col col-lg-6">
                                        <CampoFicha label="Domicilio" value={this.props.parte.Domicilio} />
                                    </div>
                                    <div className="col col-lg-6">
                                        <CampoFicha label="Contacto" value={this.props.parte.PersonaContactoLc} />
                                    </div>
                                    <div className="col-12">
                                        <ServiceInput onChange={this.handleChange}
                                            name="Observaciones"
                                            title="Observaciones"
                                            placeholder="Observaciones"
                                            type="textarea"
                                            rows={4}
                                            default={this.state.Observaciones} />
                                        {this.state.msgError ? <div className="alert alert-danger">{this.state.msgError}</div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={this.handleAcept}>Aceptar</button>
                    </Modal.Footer>
                </Modal>

            </>
        );
        //}
    }
}

export default ModalFinalizarParte;

