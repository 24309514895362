import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import ListaPartes from './ListaPartes';
import ListaImputaciones from 'pages/Imputaciones/ListaImputaciones';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from '@fortawesome/free-solid-svg-icons';

class Partes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            parte: {},
            firstday: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
            lastday: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }


        // Select parte
        this.seleccionarParte = this.seleccionarParte.bind(this);
        this.verListaPartes = this.verListaPartes.bind(this);

        // Nueva
        this.handleChange = this.handleChange.bind(this);
    }


    //////////////////////////
    componentDidMount() {
        //this._isMounted = true;
    }

    //componentWillUnmount() {
    //    //this._isMounted = false;
    //}


    /////////////////////
    // Select parte
    seleccionarParte(item) {
        //console.log("seleccionarParte::item::", item);
        if (item) {
            this.setState({
                parte: {}
            });
            let data = {
                NumeroParteLc: item.NumeroParteLc,
                SerieParteLc: item.SerieParteLc,
                EjercicioParteLc: item.EjercicioParteLc,
                CodigoTipoParteLc: item.CodigoTipoParteLc
            }
            // Parte
            api.post("/LeerParte", data).then(res => {
                //console.log("res.data::", res.data);
                this.setState({
                    parte: res.data
                });
            });
        }
        else {
            this.setState({
                parte: {}
            });
        }
    }

    verListaPartes() {
        this.setState({
            parte: {}
        });
    }

    /////////////////////
    // Nueva imputaci�n
    handleChange(field, evt) {
        //console.log("ModalImputacion::field::", field);
        //console.log("ModalImputacion::evt::", evt);
        const name = evt.name;
        this.setState({
            [name]: field.value
        });
    }

    render() {

        if (this.state.isLoading) {
            return <div>Loading....</div>
        }
        else {
            return (
                <>
                    {/* Lista de partes */}
                    {!this.state.parte.NumeroParteLc ?
                        <ListaPartes onSelect={this.seleccionarParte} />
                        : null
                    }

                    {/* Lista de imputaciones */}
                    {this.state.parte.NumeroParteLc ?
                        <>

                            {/*JSON.stringify(this.state.parte)*/}
                            <div className="border p-1 mt-1 bg-light">
                                <div className="d-flex flex-row justify-content-between">
                                    <div>Parte</div>
                                    <div className="btn-toolbar">
                                        <button type="button" className="btn btn-xs btn-outline-primary" onClick={() => this.verListaPartes()}><FontAwesomeIcon icon={faBackward} />&nbsp;Volver a lista de partes</button>
                                    </div>
                                </div>
                            </div>

                            <TablaParte parte={this.state.parte} />

                            <ListaImputaciones parte={this.state.parte} />
                        </>
                        :
                        null
                    }

                </>
            );
        }
    }
}
export default Partes;

function TablaParte(props) {
    return (
        <table className='table table-sm table-hover' aria-labelledby="tabelLabel">
            <thead className="thead-dark">
                <tr>
                    <th>&nbsp;</th>
                    <th>Serie</th>
                    <th>Parte</th>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Cliente</th>
                    <th>Responsable Parte</th>
                    <th>T&eacute;cnico Asignado</th>
                    <th>Proyecto</th>
                    <th>Horas Vendidas</th>
                    <th>Horas Realizadas</th>
{/*                    <th>Horas Facturables</th>*/}
   {/*                 <th>Horas Facturables</th>*/}
                    <th style={{ width: '400px' }}>Observaciones</th>
                    <th>Estado</th>
                    <th style={{ width: '100px' }}></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>{props.parte.SerieParteLc}</td>
                    <td>{props.parte.NumeroParteLc}</td>
                    <td>{props.parte.CodigoTipoParteLc}</td>
                    <td>{props.parte.Fecha}</td>
                    <td>{props.parte.NombreCliente}</td>
                    <td>{props.parte.NombreResponsableParteLc}</td>
                    <td>{props.parte.NombreTecnicoAsignadoLc}</td>
                    <td>{props.parte.CodigoProyecto}</td>
                    <td style={{ textAlign: 'center' }}>{props.parte.ESMA_HorasDisponibles}</td>
                    <td style={{ textAlign: 'center' }}>{props.parte.HorasRealizadas}</td>
                    {/*<td style={{ textAlign: 'center' }}>{props.parte.TotalHorasFacturables}</td>*/}
                   {/* <td style={{ textAlign: 'center' }}>{props.parte.HorasRealizadas}</td>*/}
                    <td>{props.parte.Observaciones}</td>
                    <td>{props.parte.StatusParteLcStr}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
}






