import React, { Component, useState, useEffect } from 'react';
import * as api from 'components/Util/Api';
import { AppContext } from '../../App'

import ConfirmDialog from 'components/Util/ConfirmDialog';

import Modal from 'react-bootstrap/Modal';
import CampoFicha from 'components/Util/CampoFicha';
import ServiceInput from 'components/Util/ServiceInput';
import ServiceSelect from 'components/Util/ServiceSelect';
import ServiceDate from 'components/Util/ServiceDate';
import Dropdown from 'react-bootstrap/Dropdown';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*import { faEdit } from '@fortawesome/free-regular-svg-icons';*/
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';



class ModalDetalleValoracion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.valoracion,
            isLoading: true,
            startDate: new Date(),
            FechaRechazo: null,
            listaObservaciones: [],
            Observacion: '',
            listaEstados: []
        }
        ModalDetalleValoracion.contextType = AppContext;

        this.formatDate = this.formatDate.bind(this);

        this.cargarEstados = this.cargarEstados.bind(this);

        this.leerValoraciones = this.leerValoraciones.bind(this);

        this.handleChange = this.handleChange.bind(this);
        // Modal
        this.handleClose = this.handleClose.bind(this);

        this.handleAddObs = this.handleAddObs.bind(this);

        this.handleSave = this.handleSave.bind(this);

        this.handleEliminarObservacion = this.handleEliminarObservacion.bind(this);

        this.eliminarObservacion = this.eliminarObservacion.bind(this);

        this.cancelEliminarObservacion = this.cancelEliminarObservacion.bind(this);

    }

    ///
    componentDidMount() {
        //console.log("ModalDetalleValoracion::componentDidMount:");
        this.cargarEstados();
        this.leerValoraciones();
        /*console.log(this.props.valoracion.CodigoEquipoAplicadoresLc);*/
        //console.log(this.context);
        
    }


    ////////////////////
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    // 

    cargarEstados() {
        // Lista Estados
        api.post("/EstadosValoracionesSelect", {}).then(res => {
            //console.log("ListaTecnicosSelect::res.data::", res.data);
            this.setState({
                listaEstados: res.data
            });
            /*console.log("this.state.listaEstados:", this.state.listaEstados);*/
        });
    }

    leerValoraciones() {
        
        let data = {
            Id: this.props.valoracion.Id,
            tecnicoAsignado: this.props.valoracion.TecnicoAsignadoLc
        }
        // Valoracion
        api.post("/LeerValoracion", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({
                isLoading: false,
                valoracion: res.data,
                FechaRechazo: res.data.FechaRechazo,
                FechaContacto: res.data.FechaConctacto,
                EstadoDefault: res.data.ED_StatusReferencia,
                EstadoDefaulText: res.data.ED_StatusReferenciaText,
                listaObservaciones: res.data.listaobservaciones,
                Horas: res.data.Horas
            });

            //console.log("res.data::", res.data);

        });
    }

    handleChange(name, value) {
        //console.log("ModalValoracion::name::", name);
        //console.log("ModalValoracion::value::", value);

        this.setState({
            [name]: value
        });
    }

    handleAddObs() {

     /*   console.log("this.state", this.props.valoracion.TecnicoAsignadoLc);*/

        if (this.state.Observacion) {

            let data = {
                Observacion: this.state.Observacion,
                FechaObservacion: this.state.FechaObservacion,
                tecnicoAsignado: this.props.valoracion.TecnicoAsignadoLc,
                Id: this.props.valoracion.Id
            }

            api.post("/AddObservacion", data).then(res => {
                //console.log("res.data::", res.data);

                /* this.setState({ isLoading: true});*/

                api.post("/LeerValoracion", data).then(res2 => {
                    //console.log("res.data::", res.data);
                    this.setState({
                        valoracion: res2.data,
                        FechaRechazo: this.isValidDate(this.state.FechaRechazo) ? this.state.FechaRechazo : res2.data.FechaRechazo,
                        FechaContacto: this.isValidDate(this.state.FechaContacto) ? this.state.FechaContacto : res2.data.FechaConctacto,
                        startDate: new Date(),
                        Observacion: ''
                    });

                    /*   this.props.onChange();*/
                    /* console.log("this.Observacion.value", this.state.Observacion);*/

                });
            });
        }

    }
    // Modal
    handleClose() {
        this.props.onClose();
        this.setState({
            showModal: false
        });
    }

    handleSave() {
        //console.log("handleSave::this.state::", this.state);
        //console.log("handleSave::this.props.parte::", this.props.parte);

        if (this.validate()) {

            let data = {
                Estado: this.state.Estados,
                FechaContacto: this.isValidDate(this.state.FechaContacto) ? this.formatDate(this.state.FechaContacto) : "",
                TextoOferta: this.state.ED_TextoOferta,
                MotivoRechazo: this.state.ED_MotivoRechazo,
                FechaRechazo: this.isValidDate(this.state.FechaRechazo) ? this.formatDate(this.state.FechaRechazo) : "",
                Horas: this.state.Horas,
                tecnicoAsignado: this.state.TecnicoAsignadoLc,
                Id: this.state.valoracion.Id,
            }

            api.post("/ActualizarValoracion", data).then(res => {
                var resultado = res.data;
                if (resultado.Correcto) {
                    /*this.props.onClose();*/
                    this.setState({ msgCorrecto: resultado.Mensaje });
                }
                else {
                    this.setState({ msgError: resultado.Mensaje });
                    /*this.props.onClose();*/
                }

            });
        }
    }

    handleEliminarObservacion = item => () => {

        /*console.log("handleEliminarObservacion::", item);*/
        this.setState({
            showConfirmDelete: true,
            observacion: item ? item : null
        });
    }

    eliminarObservacion(item) {
        /*console.log("eliminarObservacion::item::", item);*/
        this.setState({
            showConfirmDelete: false
        });
        if (item) {
            this.setState({ isLoading: true });

            let data = {
                Id: item.Id,
                FechaObservacion: item.Fecha
            }
            api.post("/DelObservacion", data).then(res => {
                //console.log("res.data::", res.data);
                this.leerValoraciones();
            });

        }
    }

    cancelEliminarObservacion() {
        this.setState({
            showConfirmDelete: false
        });
    }

    validate() {
        var valid = false;

        if (this.state.ED_MotivoRechazo && !this.isValidDate(this.state.FechaRechazo)) {
            this.setState({ msgError: 'Debe indicar la fecha del rechazo' });
        }
        //else if (!this.state.CodigoTipoFaseLc) {
        //    this.setState({ msgError: 'Debe informar la fase' });
        //}
        //else if (!this.state.CodigoActividadParteLc) {
        //    this.setState({ msgError: 'Debe informar la actividad' });
        //}
        //else if (!this.state.TecnicoAsignadoLc) {
        //    this.setState({ msgError: 'Debe informar el tecnico asignado' });
        //}
        //else if (!this.state.Observaciones) {
        //    this.setState({ msgError: 'Debe informar las observaciones' });
        //}
        else {
            valid = true;
        }

        return valid;
    }

    isValidDate(d) {

        var valid_date = false;

        if (new Date(d).getFullYear() == 1)
            valid_date = false;

        if (d instanceof Date && !isNaN(d))
            valid_date = true;

        var myDate = d;
        myDate = myDate.toString().split('/');

        if (myDate.length == 2)
            valid_date = true;

        return valid_date;
    }


    render() {
        if (this.state.isLoading) {
            return (
                <Modal
                    size="xl"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Numero OP:&nbsp;{this.props.valoracion.NumeroOP}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Loading....</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else {
            return (
                <>
                    {/* Valoracion */}
                    <Modal
                        size="xl"
                        show={this.props.show}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false} >
                        <Modal.Header className="py-1" closeButton>
                            <Modal.Title className="letra20">
                                Numero OP:&nbsp;{this.state.valoracion.NumeroOP}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col">
                                <div className="row pl-0">
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Ejercicio" value={this.state.valoracion.EjercicioOP} />
                                    </div>
                                    <div className="col col-lg-1">
                                        <CampoFicha label="Serie" value={this.state.valoracion.SerieOP} />
                                    </div>
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Fecha" value={this.state.valoracion.FechaFmt} />
                                    </div>
                                    <div className="col col-lg-4">
                                        <CampoFicha label="Numero OP" value={this.state.valoracion.NumeroOP} />
                                    </div>
                                    <div className="col col-lg-3">
                                        <CampoFicha label="Consultor" value={this.state.valoracion.NombreConsultor} />
                                    </div>
                                </div>
                            </div>

                            <div className="font-weight-bold mt-2">Cliente Potencial</div>
                            <div className="col">
                                <div className="row pl-0">
                                    <div className="col col-lg-4">
                                        <CampoFicha label="Razón Social" value={this.state.valoracion.RazonSocial} />
                                    </div>
                                    <div className="col col-lg-4">
                                        <CampoFicha label="Contacto" value={this.state.valoracion.Nombre} />
                                    </div>
                                    <div className="col col-lg-4">
                                        <CampoFicha label="Correo Electronico" value={this.state.valoracion.EMail1} />
                                    </div>
                                </div>
                                <div className="row pl-0">

                                    <div className="col col-lg-2">
                                        <CampoFicha label="CIF/DNI" value={this.state.valoracion.CifDni} />
                                    </div>
                                    <div className="col col-lg-2">
                                        <CampoFicha label="Telefono" value={this.state.valoracion.Telefono} />
                                    </div>

                                    <div className="col col-lg-2">
                                        <CampoFicha label="Tipo Cliente" value={this.state.valoracion.CodigoCategoriaCliente_Text} />
                                    </div>
                                    <div className="col col-lg-3">
                                        <ServiceSelect onChange={this.handleChange} name="Estados" list={this.state.listaEstados} title="Estado" default={{ value: this.state.EstadoDefault, label: this.state.EstadoDefaulText }} />
                                    </div>
                                    {this.context.Empleado.CodigoCategoriaEmpleadoLc == 'DIR' ? (
                                        <div className="col col-lg-3">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="TecnicoAsignadoLc"
                                                service="ListaTecnicosNoExtSelect"
                                                title="Asignar a otro t&eacute;cnico"
                                                default={this.props.valoracion.TecnicoAsignadoLc ? { value: this.props.valoracion.TecnicoAsignadoLc, label: this.props.valoracion.NombreConsultor } : null} />
                                        </div>) : ""
                                    }
                                    {this.props.valoracion.CodigoEquipoAplicadoresLc == 'TC200' ? (
                                        <div className="col col-lg-3">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="TecnicoAsignadoLc"
                                                service="ListaEquipoAplicadoresTC200Select"
                                                title="Asignar a otro t&eacute;cnico"
                                                default={this.props.valoracion.TecnicoAsignadoLc ? { value: this.props.valoracion.TecnicoAsignadoLc, label: this.props.valoracion.NombreConsultor } : null} />
                                        </div>) : ""
                                    }
                                    {this.props.valoracion.CodigoEquipoAplicadoresLc == 'TC50' ? (
                                        <div className="col col-lg-3">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="TecnicoAsignadoLc"
                                                service="ListaEquipoAplicadoresTC50Select"
                                                title="Asignar a otro t&eacute;cnico"
                                                default={this.props.valoracion.TecnicoAsignadoLc ? { value: this.props.valoracion.TecnicoAsignadoLc, label: this.props.valoracion.NombreConsultor } : null} />
                                        </div>) : ""
                                    }
                                    {this.props.valoracion.CodigoEquipoAplicadoresLc == 'EQ2' ? (
                                        <div className="col col-lg-3">
                                            <ServiceSelect onChange={this.handleChange}
                                                name="TecnicoAsignadoLc"
                                                service="ListaEquipoAplicadoresEQ2Select"
                                                title="Asignar a otro t&eacute;cnico"
                                                default={this.props.valoracion.TecnicoAsignadoLc ? { value: this.props.valoracion.TecnicoAsignadoLc, label: this.props.valoracion.NombreConsultor } : null} />
                                        </div>) : ""
                                    }
                                </div>
                            </div>
                            <div className="mt-1">
                                <div className="d-flex flex-column">
                                    <div className="font-weight-bold mt-2">Solicita</div>
                                    <div className="col">
                                        <textarea className="form-control" style={{ fontSize: 'small' }} rows="2" value={this.state.valoracion.ED_Solicita ? this.state.valoracion.ED_Solicita : ''} disabled />
                                    </div>
                                </div>

                                <div className="d-flex flex-column">
                                    <div>
                                        <div className="col col-lg-3">
                                            <ServiceDate onChange={this.handleChange} name="FechaContacto" title="Fecha Contacto" default={new Date(this.state.FechaContacto).getFullYear() != 1 ? new Date(this.state.FechaContacto) : ""} disabled={new Date(this.state.FechaContacto).getFullYear() != 1 ? true : false} />
                                        </div>
                                    </div>

                                    <div className="font-weight-bold mt-2">Texto Oferta</div>
                                    <div className="col">
                                        <ServiceInput onChange={this.handleChange}
                                            name="ED_TextoOferta"
                                            type="textarea"
                                            default={this.state.valoracion.ED_TextoOferta ? this.state.valoracion.ED_TextoOferta : ''}
                                            rows={2} />
                                    </div>
                                    <div className="font-weight-bold mt-2">Horas Oferta</div>
                                    <div className="col col-lg-2">
                                    <ServiceInput onChange={this.handleChange}
                                        name="Horas"
                                        default={this.props.valoracion.Horas}
                                        title=""
                                        placeholder="Horas"
                                        type="number" />
                                    </div>
                                </div>

                                <div className="d-flex flex-column">
                                    <div className="font-weight-bold mt-2">Motivo Rechazo</div>
                                    <div className="col">
                                        <ServiceInput onChange={this.handleChange}
                                            name="ED_MotivoRechazo"
                                            type="textarea"
                                            default={this.state.valoracion.ED_MotivoRechazo ? this.state.valoracion.ED_MotivoRechazo : ''}
                                            rows={2} />
                                    </div>
                                    <div className="col col-lg-3">
                                        <ServiceDate onChange={this.handleChange} name="FechaRechazo" title="Fecha Rechazo" default={new Date(this.state.FechaRechazo).getFullYear() != 1 ? new Date(this.state.FechaRechazo) : ""} disabled={new Date(this.state.FechaRechazo).getFullYear() != 1 ? true : false} />
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="font-weight-bold mt-2">Observaciones</div>
                                    <div className="col mt-1">
                                        <div className="row d-flex align-items-end">
                                            <div className="col col-lg-2">
                                                <ServiceDate onChange={this.handleChange} name="FechaObservacion" title="Fecha" default={this.state.startDate} />
                                            </div>
                                            <div className="col col-lg-8">
                                                <ServiceInput id="Observacion" className="form-control form-control-sm" onChange={this.handleChange} name="Observacion" type="Text" default={this.state.Observacion} />
                                            </div>
                                            <div className="col col-lg-2 mx-0 px-0">
                                                <button type="button" onClick={this.handleAddObs} className="btn btn-sm btn-outline-primary ml-1"><FontAwesomeIcon icon={faPlus} />&nbsp;Añadir Observación</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="col mt-2">
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="w-100">
                                                {/* Lista de observaciones */}
                                                {!this.state.valoracion.listaobservaciones ? null :

                                                    <table className='table table-sm table-bordered'>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th style={{ width: '170px' }}>Empleado</th>
                                                                <th style={{ width: '70px' }}>Fecha</th>
                                                                <th>Observacion</th>
                                                                <th style={{ width: '40px' }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!this.state.valoracion.listaobservaciones.length ? null :
                                                                this.state.valoracion.listaobservaciones.map(item =>
                                                                    <tr className="cursorpointer" key={item.Id}>
                                                                        <td>{item.EmpleadoObservacion}</td>
                                                                        <td>{item.FechaFmt}</td>
                                                                        <td>{item.Observaciones}</td>
                                                                        <td className="text-center">
                                                                            <button type="button" onClick={this.handleEliminarObservacion(item)} className="btn btn-xs btn-outline-danger"><FontAwesomeIcon icon={faTrash} /></button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            {this.state.valoracion.listaobservaciones.length == 0 &&
                                                                <tr><td colSpan="3">No hay datos para la consulta</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                }


                                                {/* Confirm */}
                                                {(this.state.showConfirmDelete) ?
                                                    <ConfirmDialog
                                                        show={this.state.showConfirmDelete}
                                                        item={this.state.observacion}
                                                        title="Eliminar observaci&oacute;n"
                                                        message="Desea eliminar la observaci&oacute;n?"
                                                        onAcept={this.eliminarObservacion}
                                                        onCancel={this.cancelEliminarObservacion}
                                                    />
                                                    :
                                                    null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.msgCorrecto ? <div className="alert alert-sucess">{this.state.msgCorrecto}</div> : null}
                            {this.state.msgError ? <div className="alert alert-danger">{this.state.msgError}</div> : null}
                            <button type="button" className="btn btn-sm btn-primary" onClick={this.handleSave}>Guardar</button>
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>Cerrar</button>
                        </Modal.Footer>
                    </Modal>


                </>
            );
        }
    }
}

export default ModalDetalleValoracion;
