
import React, { Component } from 'react';
import * as api from 'components/Util/Api';

import Modal from 'react-bootstrap/Modal';
import logo from 'img/logo.png'; // Tell Webpack this JS file uses this image
import ParteAsistencia from 'pdf/ParteAsistencia/ParteAsistencia';

import ServiceImage from 'components/Util/ServiceImage';
import ServiceInput from 'components/Util/ServiceInput';
import ServiceBool from 'components/Util/ServiceBool';
import ServiceFileDnd from 'components/Util/ServiceFileDnd';
import CampoFicha from 'components/Util/CampoFicha';

class ModalImprimirParteAsistencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            filename: 'ParteAsistencia_' + this.props.parte.NumeroParteLc.toString() + '_' + this.props.parte.NombreCliente.replace(/[/\\?%*:|"<> ]/g, '_').toString() + '.pdf'
        }

        this.handleChange = this.handleChange.bind(this);

        // Modal
        this.handleClose = this.handleClose.bind(this);
    }

    ///
    componentDidMount() {
        //console.log("ModalImprimirParteAsistencia::componentDidMount:");
        this.setState({
            isLoading: true,
            parte: {}
        });
        let data = {
            CodigoEmpresa: this.props.parte.CodigoEmpresa,
            NumeroParteLc: this.props.parte.NumeroParteLc,
            SerieParteLc: this.props.parte.SerieParteLc,
            EjercicioParteLc: this.props.parte.EjercicioParteLc,
            CodigoTipoParteLc: this.props.parte.CodigoTipoParteLc
        }
        // Parte
        api.post("/LeerParte", data).then(res => {
            //console.log("res.data::", res.data);
            this.setState({
                isLoading: false,
                parte: res.data,
                TrabajosRealizados: res.data.TrabajosRealizados
            });
            /*console.log("props.prueba::", this.state.parte.ObservacionesImputaciones);*/
        });
    }

    // Modal
    handleClose() {
        this.props.onClose();
        this.setState({
            showModal: false
        });
    }

    handleChange(name, value) {
        //console.log("Partes::name::", name);
        //console.log("Partes::value::", value);
        this.listapartes = '';
        this.setState({
            [name]: value
        });
    }

    handleParteDescargado = () => {
    }
    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>
        }
        else {
            return (
                <>
                    <Modal
                        size="lg"
                        show={this.props.show}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Imprimir parte de asistencia
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="card card-body">

                                <CabeceraParteAsistencia parte={this.props.parte} />

                                <div className="row">
                                    <div className="col-12">
                                        <ServiceInput onChange={this.handleChange}
                                            name="FechasAsistencia"
                                            title="Fechas de la asistencia"
                                            type="textarea"
                                            rows={1} />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-12">
                                        <ServiceInput onChange={this.handleChange}
                                            name="TrabajosRealizados"
                                            title="Trabajos realizados"
                                            type="textarea"
                                            default={this.state.parte.TrabajosRealizados ? this.state.parte.TrabajosRealizados : ""}
                                            rows={4} />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-2">
                                        <ServiceBool onChange={this.handleChange}
                                            name="InspeccionFinal" title="Inspecci&oacute;n final" size="sm" default={false} />
                                    </div>
                                    <div className="col-10">
                                        {this.state.InspeccionFinal ?
                                            <ServiceInput onChange={this.handleChange}
                                                name="InspeccionFinalTexto" title="Descripci&oacute;n" />
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-2">
                                        <ServiceBool onChange={this.handleChange}
                                            name="CerrarParte" title="Cerrar parte" size="sm" default={false} />
                                    </div>
                                </div>

                                {/*<div className="row">*/}
                                {/*  <div className="col-6">*/}
                                {/*    <ServiceImage onChange={this.handleChange}*/}
                                {/*      name="Firma" title="Firma t&eacute;cnico asignado" />*/}
                                {/*  </div>*/}
                                {/*</div>*/}

                                <div className="row mt-1">
                                    <div className="col-6">
                                        <CampoFicha label="T&eacute;cnico" value={this.props.parte.NombreEmpleado} />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-6">
                                        <ServiceFileDnd onChange={this.handleChange}
                                            name="Firma" title="Firma t&eacute;cnico asignado" message="Deja tu firma aqu&iacute; (o clic para seleccionar archivo)" />
                                    </div>
                                </div>



                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-sm btn-danger" onClick={this.handleClose}>
                                Cerrar
                            </button>
                            <ParteAsistencia filename={this.state.filename} parte={this.state.parte} data={this.state} handleParteDescargado={this.handleParteDescargado}/>
                        </Modal.Footer>
                    </Modal>

                </>
            );
        }
    }
}

export default ModalImprimirParteAsistencia;

{/* Cabecera parte de asistencia */ }
function CabeceraParteAsistencia(props) {

    return (
        <>
            <div className="d-flex flex-row justify-content-between">
                <img src={logo} alt="Logo" width="250px" height="60px" />
                <div className="w-40">
                    <table className="table table-sm table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th>Tipo</th>
                                <th>Parte</th>
                                <th>Fecha parte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.parte.CodigoTipoParteLc}</td>
                                <td>{props.parte.NumeroParteLc}</td>
                                <td>{props.parte.Fecha}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );

}

